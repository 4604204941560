/* eslint-disable import/no-anonymous-default-export */
import axios from "../axios";

export default {
  getBeneficiaryList(params) {
    return axios.post("/users/recipient", params);
  },
  addBeneficiary(params) {
    return axios.post("/users/recipient/beneficiary", params);
  },
  verifyAccount(params) {
    return axios.post("/users/recipient/moneywave", params);
  },
  getAccountName(params) {
    return axios.post("/users/recipient/get_account_name", params);
  },
  deleteBeneficiary(id) {
    return axios.delete("/users/recipient", { data: { id } });
  },
  getBankListMW(country, type = "") {
    return axios.post(`/users/recipient/banks?country=${country}&type=${type}`);
  },
  getBranchListMW(country, bankcode) {
    return axios.post(
      `/users/recipient/banks/branch?country=${country}&bankcode=${bankcode}`
    );
  },
  getBankListJSON(country, type = "") {
    return axios.get(`/api/json/banks/${country}?type=${type}`);
  },
  getBranchListJSON(country, bankcode) {
    return axios.get(`/api/json/branch/${country}?bank=${bankcode}`);
  },
};

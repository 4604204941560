import styled, { css } from "styled-components";

export const IconWrapper = styled.span`
  display: inline-block;
  position: relative;
  // padding-left: 15em;
  line-height: 0;
`;

export const buttonLayout = () => css`
  ${({ loading }) =>
    css`
      min-width: 190px;
      width: ${({ width }) => (width ? width : "100%")};
      // border-radius: 50px;
      color: ${({ theme }) => theme.palette.Green100};
      background-color: ${({ theme }) => theme.palette.Yellow10};
      box-shadow: 1px 15px 20px -10px ${({ theme }) => theme.palette.Yellow10};
      padding: 13px 15px;
      text-align: center;
      text-transform: uppercase;
      border: none;
    `}
`;

export const Wrapper = styled.button`
  ${buttonLayout}
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "18px")};
  font-stretch: normal;
  line-height: 27px;
  letter-spacing: 0.36px;
  color: #1a4975;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "700")};
  text-transform: capitalize;
  vertical-align: middle;

  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    opacity: 0.6;
  `}
  /* @media screen and (min-width: 600px) and (max-width: 767px) {
    min-width: 140px;
    padding: 10px;
    font-size: 16px;
  } */

  @media screen and (max-width: 767px) {
    min-width: 140px;
    padding: 10px;
    font-size: 16px;
  }
`;

export const ChildWrapper = styled.span`
  align-items: center;
  display: inline-block;
  text-align: center;
  display: flex;
  ${({ icon, amountTopay }) =>
    icon || amountTopay
      ? `justify-content: space-between;`
      : `justify-content: center;`}
  white-space: nowrap;
`;

export const PayWrapper = styled.span`
  display: inline-block;
  position: relative;
  top: 4px;
`;

import React from "react";
import { Form, Input, Button, Select, Row, Col } from "antd";
import { categories } from "./data";
import { RightOutlined } from "@ant-design/icons";
const { Option } = Select;
const { TextArea } = Input;
const CreateSupportTicket = (props) => {
  const { onSubmit } = props;
  return (
    <Form
      name="support-ticket"
      onFinish={onSubmit}
      scrollToFirstError
      layout="vertical"
    >
      <Row gutter={10}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Form.Item
            name="fullname"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
                whitespace: true,
              },
            ]}
          >
            <Input className="cr-input" placeholder="Enter Name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input className="cr-input" placeholder="Enter Email" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Form.Item
            name="phone_number"
            label="Phone Number"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input className="cr-input" placeholder="+61 45737 849 98" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Form.Item
            name="category"
            label="Select Category"
            rules={[{ required: true, message: "Please select category!" }]}
          >
            <Select placeholder="Choose One" className="cr-select">
              {categories.map((c, i) => (
                <Option key={i} value={c.value}>
                  {c.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="comments"
            label="Comments"
            rules={[{ required: true, message: "Please input comments!" }]}
          >
            <TextArea
              className="cr-textarea"
              rows={5}
              placeholder="write a comment"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          className="btn-green w-272 h-48 with-icon"
          shape="round"
          size="large"
          htmlType="submit"
        >
          Submit
          <RightOutlined />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateSupportTicket;

import styled from "styled-components";
import { Card } from "antd";

export const StyledLogin = styled.span``;

export const PaymentCard = styled(Card)`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  max-width: 500px;
  margin: 25px auto !important;

  @media (min-width: 768px) {
    max-width: 600px;
    width: 630px;
  }

  .ant-card-head {
    border: none;
  }

  .balance-card-wrapper{
    margin-top: 0;
  }

  .ant-card-head-title {
    font-size: 22px;
    font-weight: 500;
    color: #1a4975;
    position: relative;
    text-align : center;

    &:after {
      content: " ";
      position: absolute;
      height: 4px;
      width: 40px;
      bottom: 0;
      left: 0;
    }
  }
`;
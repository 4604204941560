import React from "react";
import PropTypes from "prop-types";
import { Grid as ReactGrid } from "react-flexbox-grid";
import {
  Wrapper,
  TextAlign,
  AppScreenWrapper,
  TitleWrapper,
  H2,
  H4,
} from "./style";
import {
  Box,
  Grid,
  GridItem as ChakraGridItem,
  SimpleGrid,
  Heading,
  Text,
} from "@chakra-ui/react";
import LockSvg from "assets/svg/LockSvg";
import LikeSvg from "assets/svg/LikeSvg";
import StarSvg from "assets/svg/StarSvg";

const IndexLayout = ({ gridItems, subtitle, title, children }) => {
  const getIconHandler = (name) => {
    switch (name) {
      case "secureIcon":
        return <LockSvg />;
      case "simpleIcon":
        return <LikeSvg />;
      case "fastIcon":
        return <StarSvg />;

      default:
        return null;
    }
  };

  return (
    <>
      <Wrapper>
        <TextAlign>
          <ReactGrid>
            <Grid templateColumns="repeat(12, 1fr)" gap={4} sx={{ mb: 4 }}>
              <ChakraGridItem
                colStart={{ base: 1, lg: 3 }}
                colEnd={{ base: 13, lg: 11 }}
              >
                <TitleWrapper>
                  {subtitle && <H4>{subtitle}</H4>}
                  {title && <H2>{title}</H2>}
                </TitleWrapper>
              </ChakraGridItem>
            </Grid>

            <SimpleGrid
              columns={{ base: 1, lg: 3 }}
              spacingX="40px"
              spacingY="20px"
            >
              {gridItems &&
                gridItems?.map((item, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "30px",
                      background: "white",
                      borderRadius: "8px",
                    }}
                    alignItems={{ base: "center", md: "flex-start" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        w: "72px",
                        h: "72px",
                        borderRadius: "4px",
                        background: "rgba(48, 81, 120, 0.1)",
                        mb: "48px",
                      }}
                    >
                      {getIconHandler(item?.iconName)}
                    </Box>

                    <Heading
                      as="h5"
                      fontSize={{ base: "18px", md: "24px", lg: "32px" }}
                      sx={{ color: "#305178", fontWeight: 600, mb: "32px" }}
                    >
                      {item?.title}
                    </Heading>

                    <Text
                      fontSize={{ base: "16px", md: "18px" }}
                      sx={{ color: "#0F1F33" }}
                      textAlign={{ base: "center", md: "left" }}
                    >
                      {item?.subtitle}
                    </Text>
                  </Box>
                ))}
            </SimpleGrid>
          </ReactGrid>
        </TextAlign>
      </Wrapper>
      <AppScreenWrapper>{children}</AppScreenWrapper>
    </>
  );
};

IndexLayout.defaultProps = {
  gridItems: [],
};

IndexLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    })
  ),
};

export default IndexLayout;

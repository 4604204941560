import React, { useState, Suspense, useEffect } from "react";
import ClientHeader from "./ClientHeader";
//import { Container } from "reactstrap";
import { Switch, Route, Redirect } from "react-router-dom";
import clientRoutes from "../../routes";
import { Layout } from "antd";
import ClientSidebar from "./ClientSidebar";
import Store from "../../helpers/context/Store";
import { useRouter } from "helpers/hooks";
import CallbackPage from "components/SendMoney/CallbackPage";

const ClientLayout = (props) => {
  const [counter, setCounter] = useState(0);
  const router = useRouter();
  const { pathname } = router;

  const user = JSON.parse(localStorage.getItem("user"));
  const [searchString, setSearchString] = useState("");

  const [collapsed, setCollapsed] = useState(
    window.innerWidth <= 768 ? true : false
  );
  const back = () => {
    setCounter(counter + 1);
  };
  const { Content } = Layout;
  const loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  const data = {
    fullname: user?.first_name
      ? `${user.first_name} ${user.last_name}`
      : "User",
    crn: `${user.customer_id}`,
  };

  const isEmailVerified = JSON.parse(
    localStorage.getItem("user")
  )?.is_email_verify;

  const isDocVerified = JSON.parse(
    localStorage.getItem("user")
  )?.is_doc_verify;

  const isSubmited = JSON.parse(
    localStorage.getItem("user")
  )?.is_doc_submitted;

  useEffect(() => {
    if ((!isEmailVerified || !isSubmited) && ["/send-money", "/dashboard"].includes(props.location.pathname)) {
      props.history.push("/settings");
    }
  }, [isEmailVerified, props.history]);

  const search = (v) => {
    setSearchString(v);
  };

  if (pathname.endsWith("/poli/callback") || pathname.endsWith("/kaasi/callback") || pathname.endsWith("/fena/callback")) {
    return <Store>
      <CallbackPage history={props.history} />
    </Store>
  }

  return (
    <div className="client-layout-wrap">
      <Store>
        <Layout className="sider-layout" style={{ minHeight: "100vh" }}>
          <>
            <ClientSidebar
              {...props}
              {...data}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
            <Layout
              className={
                ["/send-money", "/poli/callback", "/kaasi/callback"].includes(
                  props.location.pathname
                )
                  ? "sendMoney"
                  : "dashboard"
              }
              style={{
                marginLeft: [
                  "/send-money",
                  "/poli/callback",
                  "/kaasi/callback",
                ].includes(props.location.pathname)
                  ? "initial"
                  : !isEmailVerified
                    ? "0px"
                    : "250px",
              }}
            >
              <ClientHeader
                {...props}
                {...data}
                back={back}
                search={search}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
              />
              <Content
                className={
                  ["/send-money", "/poli/callback", "/kaasi/callback"].includes(
                    props.location.pathname
                  )
                    ? "sendMoney"
                    : "settings"
                }
                style={{
                  padding: "3em 16px 0",
                  overflow: "initial",
                  background: "#ffffff",
                }}
              >
                <Suspense fallback={loading()}>
                  <Switch>
                    {clientRoutes.map(
                      (
                        {
                          path,
                          exact,
                          name,
                          component: Component,
                          ...routerProps
                        },
                        idx
                      ) => {
                        return Component ? (
                          <Route
                            key={idx}
                            path={path}
                            exact={exact}
                            name={name}
                            render={(props) => (
                              <Component
                                {...props}
                                {...routerProps}
                                back={counter}
                                search={searchString}
                              />
                            )}
                          />
                        ) : null;
                      }
                    )}
                  </Switch>
                </Suspense>
              </Content>
            </Layout>
          </>
        </Layout>
      </Store>
    </div>
  );
};

export default ClientLayout;

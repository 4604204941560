import React from "react";
import {
  StyledCompletePay,
  CardWrap,
  MessageHeadOne,
  MsgDescription,
  ThankYouWrap,
} from "./CompletePay.style";
import { CloseCircleOutlined } from "@ant-design/icons";
import Button from "@cashremit/button";

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66"
    height="66"
    viewBox="0 0 66 66"
  >
    <g id="Check" transform="translate(-747 -159)">
      <circle
        id="Ellipse_13"
        data-name="Ellipse 13"
        cx="33"
        cy="33"
        r="33"
        transform="translate(747 159)"
        fill="#d6df22"
      />
      <path
        id="interface"
        d="M0,76.65,10.273,86.943,30,67.236l-2.832-2.793L10.273,81.318l-7.48-7.48Z"
        transform="translate(765 116.557)"
        fill="#1a4975"
      />
    </g>
  </svg>
);

const CompletePay = (props) => {
  return (
    <StyledCompletePay>
      <CardWrap bordered={false}>
        {props.success ? (
          <CheckIcon />
        ) : (
          <CloseCircleOutlined style={{ color: "#f00" }} />
        )}
        <MessageHeadOne success={props.success}>{`${props.success ? "Payment is Successful" : "Payment is Failed"}`}</MessageHeadOne>
        <ThankYouWrap>
          {" "}
          {props.success
            ? props.isDocVerified === false
              ? `We have received you payment and your transaction is now waiting for payout`
              : props.description
            : props.description}
        </ThankYouWrap>
        {props.children}
        <ThankYouWrap>
          {props.isDocVerified === false ? (
            props.success ? (
              <>
                <p>{`Thank you for completing your first transaction with Remitzo!`}</p>
                <p>{` To finalize your payment to your beneficiary, we need to verify your ID.This process will take only 5 minutes, and you won't need to provide ID for your future transactions. `}</p>
                <p>{` Click the button below to start.`}</p>
              </>
            ) : (
              <>{`Thank you for using Remitzo | by CashRemit`}</>
            )
          ) : props.success ? (
            <>
              <p>{`Thank you for completing your transaction with Remitzo!`}</p>
            </>
          ) : (
            <>{`Thank you for using Remitzo | by CashRemit`}</>
          )}
        </ThankYouWrap>
        <Button onClick={props.onClick}>
          {props.isDocVerified === false ? `ID Verification` : `New Transfer`}
        </Button>
      </CardWrap>
    </StyledCompletePay>
  );
};

CompletePay.propTypes = {};

export default CompletePay;

import React, { useState } from "react";
import { Collapse, Avatar, Button, message } from "antd";
import { FAQs } from "../NeedHelp/data";
import CreateSupportTicket from "../NeedHelp/CreateSupportTicket";
import { supportAPI } from "../../http/api";
// import HomeBanner from "@cashremit/home-banner/lib/HomeBanner";
import HomeBanner from "../../@cashremit/home-banner/HomeBanner";
import Navbar from "../../@cashremit/TopNav";
import { links, auth_links } from "../Landing/data";
import styled from "styled-components";

import { MainWrapper, Section } from "../../containers/globals/layout";

const AccordionWrapper = styled.div`
  margin-top: 10em;
  display: flex;
  flex-direction: column;
  padding: 0 20em;
  .ant-collapse {
    border: 0;
    border-bottom: 20px solid #fbfbfb;
    background: #ffffff;
  }
  .ant-avatar {
    width: 50px;
    height: 50px;
    line-height: 48px;
    margin-right: 13px;
    background-color: #f2f2f2;
    color: #666;
  }

  .faq-title {
    font-size: 20px;
    position: relative;
    top: 5px;
  }

  .ant-collapse-extra {
    position: relative;
    top: -30px;
  }

  .ant-collapse-content-box {
    font-size: 16px;
    background-color: #064980;
    color: #ffffff;
    border-radius: 5px;
  }
`;

const FormWrapper = styled.div`
  margin-top: -10em;
  padding: 0 2em;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 20em;
  }
`;

const { Panel } = Collapse;

const token = localStorage.getItem("utoken")

const NeedHelp = (props) => {
  const [ticket, setTicket] = useState(false);

  const onSubmitHandler = (values) => {
    supportAPI
      .addSupportTicket(values)
      .then(({ data }) => {
        message.success(data.message + ` #${data.data.ticket_number}`);
        setTicket(!ticket);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const rightArrowIcon = () => (
    <svg
      id="Group_108"
      data-name="Group 108"
      xmlns="http://www.w3.org/2000/svg"
      width="22.657"
      height="14"
      viewBox="0 0 22.657 14"
      style={{ marginTop: "60px" }}
    >
      <path
        id="Path_160"
        data-name="Path 160"
        d="M19.985,48.907l-8.657,8.657L2.672,48.907,0,51.579,11.328,62.907,22.657,51.579Z"
        transform="translate(0 -48.907)"
        fill="#d6d6d6"
      />
    </svg>
  );

  return (
    <MainWrapper>
      <Section disablePaddingTop>
        {/* <HomeBanner
          style={{ minHeight: "200px" }}
          topNavComp={
            <Navbar
              isbgImage={false}
              isBgColor
              links={links}
              auth_links={auth_links}
              isBgWhite={false}
            />
          }
        > */}
        {!ticket && (
          <AccordionWrapper>
            <div className="faq">
              <Collapse accordion expandIconPosition={`right`}>
                {FAQs.map((tx, idx) => (
                  <Panel
                    className="panel-header mb-2"
                    showArrow={false}
                    extra={rightArrowIcon()}
                    header={
                      <div>
                        <Avatar size="small">
                          {`0${idx + 1}`.slice(-2)}
                        </Avatar>
                        <span className="faq-title">{tx.q}</span>
                      </div>
                    }
                    key={idx}
                  >
                    <p>{tx.a}</p>
                  </Panel>
                ))}
                {FAQs.length === 0 && (
                  <div style={{ textAlign: "center", margin: "40px" }}>
                    {" "}
                    No Records Found !{" "}
                  </div>
                )}
              </Collapse>
              <div
                className="footer-load-more"
                style={{ textAlign: "center", margin: "20px" }}
              >
                <Button
                  onClick={() => setTicket(!ticket)}
                  type="primary"
                  className="btn-green h-48"
                >
                  Create New Support Ticket
                </Button>
              </div>
            </div>
          </AccordionWrapper>
        )}
        {/* </HomeBanner> */}
      </Section>

      <Section>
        {ticket && (
          <FormWrapper>
            <div className="help-n-support">
              <h1 className="primary-heading">Need Help</h1>
              <hr className="hr-line" />
              <CreateSupportTicket onSubmit={onSubmitHandler} />
            </div>
          </FormWrapper>
        )}
      </Section>
    </MainWrapper>
  );
};

export default NeedHelp;

import styled, { css } from "styled-components";
import { calculateMaxWidth } from "../globals/function";
import { hideVisually, stripUnit } from "polished";
import { Card, Form } from "antd";
const { Item } = Form;
export const MainWrapper = styled.div`
  overflow: hidden;
`;

export const Grid = styled.div`
  width: 100%;
  max-width: ${({ theme, fullWidth }) =>
    !fullWidth && `${calculateMaxWidth(theme.gridWidth, theme.layout.xxs)}px`};
  padding: ${({ theme }) => `0 ${theme.space.lg}`};
  margin: 0 auto;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${({ theme }) => `0 ${theme.space.md}`};
  }
`;

export const Section = styled.section`
  padding: ${({ theme }) => theme.space.xl} 0;
  ${({ disablePaddingTop }) =>
    disablePaddingTop &&
    css`
      padding-top: 0;
    `}
  ${({ disablePaddingBottom }) =>
    disablePaddingBottom &&
    css`
      padding-bottom: 0;
    `}
  ${({ alignCenter }) =>
    alignCenter &&
    css`
      text-align: center;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${({ theme, size = "lg" }) => theme.layout[size]} 0;
    ${({ disablePaddingTop }) =>
    disablePaddingTop &&
    css`
        padding-top: 0;
      `}
    ${({ disablePaddingBottom }) =>
    disablePaddingBottom &&
    css`
        padding-bottom: 0;
      `}
  }

  &.refer_friend {
    @media (min-width: 768px) and (max-width: 991px) {
      /* padding-top: 40rem; */
      padding-top: ${({ classToggle }) => (classToggle ? "44rem" : "10rem")};
    }

    @media (min-width: 576px) and (max-width: 767px) {
      padding: 0;
      padding-top: ${({ classToggle }) => (classToggle ? "28rem" : "10rem")};
      /* padding-top: 23rem; */
    }

    @media (max-width: 575px) {
      /*padding-top: ${({ classToggle }) =>
    classToggle ? "23rem" : "10rem"};*/
      /* padding-top: 18rem; */
      /* padding-top: 23rem; */
    }
  }

  &.testimonial-section {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 1280px;
      margin: 0 auto;
      padding-top: 0;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 8em;
    }

    @media (max-width: 599px) {
      .testi-item {
        margin-bottom: 115px;
      }
    }
  }
`;

export const Contained = styled.div`
  margin: 0 auto;
  max-width: ${({ theme, maxWidth }) => theme.breakpoints[maxWidth]};
`;

export const ScreenReaderOnly = styled.span`
  ${hideVisually()};
`;

export const Hr = styled.hr`
  border: 0;
  border-top: solid 1px
    ${({ theme, light }) =>
    light ? theme.palette.blue50 : theme.palette.gray40};
  margin: ${({ theme, size }) => theme.space[size]} 0;
  padding-bottom: ${({ theme, offset }) =>
    stripUnit(theme.space[offset]) * 2}px;
  height: 1px;
`;

export const Heading = styled.p`
  text-transform: ${({ isUppercase }) => (isUppercase ? "uppercase" : "unset")};
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 2;
  text-align: center;
  /* color: #1a314c; */
  color: ${({ color }) => (color ? color : "#1a314c")};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 14px;
  }
`;

export const TitleHOne = styled.p`
  text-transform: ${({ isUppercase }) => (isUppercase ? "uppercase" : "unset")};

  font-size: 20px;
  line-height: 30px;
  font-weight: normal;
  /* color: #274972; */
  color: ${({ color }) => (color ? color : "#274972")};
  margin-bottom: 25px;
  text-align: center;
  @media (max-width: 599px) {
    font-size: 22px;
    font-weight: 700;
  }
`;
export const TitleHTwo = styled.p`
  text-transform: ${({ isUppercase }) => (isUppercase ? "uppercase" : "unset")};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 160%;
  /* color: #8995b8; */
  color: ${({ color }) => (color ? color : "#8995b8")};
  margin-bottom: 0;
  text-align: center;
  max-width: 830px;
  margin: 20px auto 45px;
  @media (max-width: 599px) {
    font-size: 14px;
    margin: 5px auto 25px;
  }
`;

export const LineSeperator = styled.div`
  width: 2px;
  height: 14px;
  background-color: #e8e8e8;
  margin-left: 12px;
`;

// Forgot Password (Same as Login lib Component)
export const StyledLogin = styled.span``;
export const LoginCard = styled(Card)`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  .ant-card-head {
    border: none;
  }
  .ant-card-head-title {
    font-size: 22px;
    font-weight: 700;
    position: relative;
    color: #1a4975;
    &:after {
      content: " ";
      position: absolute;
      height: 4px;
      width: 40px;
      background-color: #d6df22;
      bottom: 0;
      left: 0;
    }
  }
`;

export const FormItem = styled(Item)`
  .ant-form-item-label label {
    font-size: 15px;
    font-weight: 500;
    position: relative;
    color: #333333;
  }
  .ant-form-item-control input[type="text"],
  .ant-form-item-control .ant-input-password {
    padding: 12px 22px;
    border-radius: 10px;
  }
  .ant-checkbox + span {
    font-size: 12px;
    color: #9aa0a7;
    line-height: 15px;
  }
  .ant-checkbox-wrapper .ant-checkbox-inner {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    &:after {
      width: 8.714286px;
      height: 15.142857px;
    }
  }
  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1a4975;
    border-color: #1a4975;
  }
`;

export const FormBottom = styled.div`
  display: flex;
  justify-content: space-between;
  a.ant-btn {
    font-size: 12px;
    color: #9aa0a7;
    line-height: 30px;
    padding-bottom: 0;
    position: relative;

    &:after {
      content: "";
      width: 0px;
      height: 0px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid #9aa0a7;
      position: absolute;
      top: 50%;
      margin-top: -6px;
      right: 6px;
    }
  }
`;
export const SignUpwSocialMedia = styled.div`
  display: flex;
  max-width: 180px;
  margin: 0 auto;
  text-align: right;
  font-size: 12px;
  color: #9aa0a7;
  font-weight: 400;
`;
export const SocialIcons = styled.div`
  display: flex;

  svg {
    margin-left: 10px;
  }
`;
export const FooterSignUp = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #9aa0a7;
  font-weight: 400;
  .ant-btn-link {
    color: #1a4975;
    text-decoration: underline;
    font-size: 12px;
  }
`;

export const PageHero = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15em;
`;

export const H1 = styled.h1`
  font-size: 24px;
  &.head {
    border-bottom: solid 6px #d6df2e;
    max-width: 260px;
    padding-bottom: 15px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 54px;
  }
`;
export const H2 = styled.h2`
  font-size: 33px;
`;
export const H3 = styled.h3`
  font-size: 24px;
`;
export const H4 = styled.h4`
  font-size: 22px;
`;
export const H5 = styled.h5`
  font-size: 22px;
  font-weight: 400;
  line-height: 1.2;
  padding: 24px 0;
`;

export const P = styled.p`
  font-size: 16px;
  font-weight: 400;
  padding: 24px 0;
  opacity: 0.5;
`;

export const CTAButton = styled.button`
  display: flex;
  width: 100%;
  padding: 10px;
  background-color: #cddc39;
  border: none;
  border-radius: 17px;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
`;

export const Spacer = styled.div`
  display: flex;
  padding: 5em;
  flex-direction: column;
`;

export const CTaButtonWrapper = styled.div`
  max-width: 320px;
`;

// Card Compontent
export const Content = styled.div`
  border-radius: 2px;
  margin: 0 0 1em 0;
  width: 100%;
  flex-direction: row;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: all 200ms ease-in;

  @media screen and (min-width: 320px) {
    flex: 0 0 100%;
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: 520px) {
    flex: 0 0 50%;
    margin: 0 -6px 2vw 0;
  }
  @media screen and (min-width: 768px) {
    flex: 0 0 32%;
  }
  &:hover {
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.09);
    transform: translateY(-4px);
    transition: all 0.4s ease-in;
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: #000000;
    text-decoration: none;
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
    }
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 1em;
`;

export const Excerpt = styled.p`
  margin: 0 1rem 1rem 1rem;
  line-height: 1.6;
  padding-bottom: 1em;
`;

export const Header = styled.h1`
  display: flex;
  text-align: center;
  font-size: 40px;
  padding: 10px;
`;

export const FAB = styled.div`
  position: fixed;
  bottom: 5px;
  left: 5px;
  max-width: 50%;
  z-index: 1000;
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

import React from "react";
import {
  Document,
  Page,
  Font,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

const PrintCopy = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View
          style={{
            textAlign: "center",
            alignItems: "center",
            marginBottom: 30,
          }}
          fixed
        >
          {/* <Image style={styles.image} src="/assets/reciept-logo.png" /> */}
          <Image style={styles.image} src="/assets/newlogo24.jpeg" />
        </View>
        <Text style={styles.title}>Payment Notice</Text>
        <View style={styles.desc}>
          <View style={styles.left}>
            <Text style={styles.text}>Submitted: {data.created_at}</Text>
            <Text style={styles.text}> Transfer: {data._id} </Text>
            <Text style={styles.text}> Status: {data.status} </Text>
          </View>
          <View style={styles.right}>
            <Text style={styles.text}>{data.users?.street_name}</Text>
            <Text style={styles.text}>{data.users?.city}</Text>
            <Text style={styles.text}>
              {data.users?.state}, {data.users?.post_code}
            </Text>
            <Text style={styles.text}>{data.users?.country}</Text>
          </View>
        </View>
        <Text style={styles.title}>Transfer Details</Text>
        <View style={styles.hr}></View>
        <View style={styles.desc}>
          <View style={styles.left}>
            <Text style={styles.text}>Deposit Amount</Text>
            <Text style={styles.text}>
              {data.amount} {data.currency_code}
            </Text>
          </View>
          <View style={styles.right}>
            <Text style={styles.text}>Recipent Gets</Text>
            <Text style={styles.text}>
              {data.transfer_amount} {data.to_cur_code}
            </Text>
          </View>
        </View>
        <Text style={styles.title}>Recipent Details</Text>
        <View style={styles.hr}></View>
        <View style={styles.desc}>
          <View style={styles.left}>
            <Text style={styles.text}>
              Name:{" "}
              {`${data?.recipient?.first_name} ${data?.recipient?.last_name}`}
            </Text>
          </View>
          <View style={styles.right}>
            <Text style={styles.text}>Account Details</Text>
            <Text style={styles.text}>
              Account Number:{" "}
              {`${data.recipient?.account_number}`.substr(0, 3) + "XXXXXXX"}
            </Text>
            <Text style={styles.text}>
              Bank Name: {data.recipient?.bank_name}
            </Text>
            <Text style={styles.text}>
              Country: {data.recipient?.country_name}
            </Text>
          </View>
        </View>
        <View style={styles.hr}></View>
        <View style={styles.desc}>
          <View style={styles.left}>
            <Text style={styles.text}>Name: CASHREMIT PTY LTD</Text>
            <Text style={styles.text}>Reference: {data.refrence_no}</Text>
            <Text style={styles.text}>
              Deposit amount: {data.amount} {data.currency_code}{" "}
            </Text>
          </View>
          <View style={styles.right}>
            <Text style={styles.text}>BSB Code: 064155</Text>
            <Text style={styles.text}>Account Number: 103XXXXXX</Text>
          </View>
        </View>
        {/* <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        /> */}
      </Page>
    </Document>
  );
};

export default PrintCopy;

Font.register({
  family: "Poppins",
  fonts: [
    { src: "/assets/fonts/PoppinsRegular.ttf" }, // font-style: normal, font-weight: normal
  ],
});

Font.register({
  family: "Poppins-Bold",
  fonts: [
    {
      src: "/assets/fonts/PoppinsBold.ttf",
      fontStyle: "bold",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Poppins",
  },
  image: {
    height: 65,
    width: 188,
    alignItems: "center",
  },
  title: {
    fontWeight: 700,
    fontFamily: "Poppins-Bold",
    fontStyle: "bold",
    fontSize: 14,
  },
  desc: {
    flexDirection: "row",
    marginBottom: 15,
  },
  hr: {
    borderBottomColor: "black",
    width: "100%",
    borderBottomWidth: 1,
  },
  left: {
    marginTop: 5,
    flexGrow: 1,
    textAlign: "left",
    width: "50",
  },
  right: {
    marginTop: 5,
    flexGrow: 1,
    textAlign: "right",
    width: "50",
  },
  text: {
    fontSize: 12,
    marginBottom: 3,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

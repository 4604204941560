import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import menu from "./menu";
const { Sider } = Layout;


const ClientSidebar = (props) => {
  const [pathname, setPathname] = useState(props.location.pathname);
  // const [isVisible, setVisible] = useState(false);
  // const currentUser = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    setPathname(props.location.pathname);
  }, [props.location.pathname]);

  useEffect(() => {
    const listenerFn = () => {
      if (window.innerWidth <= 768) {
        props.setCollapsed(true);
      } else {
        props.setCollapsed(false);
      }
    };

    window.addEventListener("resize", listenerFn);

    return () => {
      window.removeEventListener("resize", listenerFn);
    };
  });

  const isEmailVerified = JSON.parse(
    localStorage.getItem("user")
  )?.is_email_verify;

  // useEffect(() => {
  //   currentUser.doc_verify && currentUser.is_email_verify && setVisible(true);
  // }, [currentUser])

  return ["/send-money", "/poli/callback", "/kaasi/callback"].includes(
    pathname
  ) ? (
    <React.Fragment></React.Fragment>
  ) : (
    isEmailVerified && <Sider
      style={{
        overflow: "auto",
        background: "#1A4975",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
      className="h-100"
      trigger={null}
      collapsible={true}
      collapsed={props.collapsed}
    >
      <div className="slider-wrap ">
        {!props.collapsed && (
          <div className="collapsed-logo">
            <img src="/assets/sidebar-logo.png" alt="logo" />
          </div>
        )}
        <Menu
          theme="light"
          selectedKeys={[`${pathname}`]}
          mode="inline"
          className="h-100"
        >
          {props.collapsed && (
            <div className="collapsed-logo">
              <img src="/assets/sidebar-logo.png" alt="logo" />
            </div>

            // <Menu.Item
            //   key={`Menu`}CashRe
            //   className="menu-humberger"
            //   onClick={() => props.setCollapsed(!props.collapsed)}
            //   icon={<RiMenu4Fill size={"22px"} />}
            // >
            //   Menu
            // </Menu.Item>
          )}
          {/* {props.collapsed ? (
            <Menu.Item
              key={`Avatar`}
              className="user-icon"
              icon={<UserOutlined size="large" />}
            >
              {props.fullname}
            </Menu.Item>
          ) : ( */}
          <div className="slider-content" style={{ textAlign: "center" }}>
            {/* {!props.collapsed && (
              <RiMenu4Fill
                // size="large"
                className="close-menu"
                onClick={() => props.setCollapsed(!props.collapsed)}
              />
            )} */}
            {/* <Avatar
                size={98}
                style={{
                  margin: "20px 0",
                  fontSize: "49px",
                  backgroundColor: "#f0f0f0",
                  color: "#0f0f0f",
                  verticalAlign: "middle",
                }}
              >
                {props.fullname
                  .split(" ")
                  .map((n) => n.substr(0, 1))
                  .join("")
                  .substr(0, 2)}
              </Avatar>
              <label
                style={{ fontSize: "26px", fontWeight: 600, marginBottom: 15 }}
              >
                {props.fullname}
              </label>
              <p>CRN: {props.crn}</p> */}
          </div>
          {/* )} */}
          {/* <hr className="divider" /> */}
          {/* <div style={{ marginTop: "15px" }}> */}
          {menu.menu.map((m, i) => (
            <Menu.Item
              className={`class-for-menuItem ${!isEmailVerified ? "disabled-menu-item" : ""}`}
              key={m.url}
              onClick={() => {
                if (!isEmailVerified) {
                  return;
                }
                props.history.push(m.url)
              }}
              icon={<m.icon />}
            >
              {props.collapsed ? null : m.name}
            </Menu.Item>
          ))}
          {/* </div> */}
        </Menu>
      </div>
      {/* <div className="logout-sidebar">
        <Menu.Item key="logout" onClick={() => logout()} icon={<FiLogIn />}>
          {props.collapsed ? null : "Logout"}
        </Menu.Item>
      </div> */}
    </Sider>
  );
};

export default ClientSidebar;

/* eslint-disable import/no-anonymous-default-export */
export const palette = {
  Green100: "#1A4975",
  Yellow20: "#D6DF22",
  Yellow10: "#D6DF22",
  Green50: "#87A8C6",
  White: "#FFFFFF",
  Power: "#FCFCFC",
  gray100: "#EAE8E9",
  blueGray100: "#EDEDED",
  Green10: "#7AD28A",
  info10: "#D7ECFF",
  danger10: "#FF7080",
  success10: "#7AD28A",
  warning10: "#F9C59A",
  info100: "#1e90ff",
  danger100: "#d4363e",
  success100: "#008000",
  warning100: "#e17a17",
};

export default {
  //** BRAND COLORS **//
  // DARK GREEN
  brandDefault: palette.Green100,
  // YELLOW
  brandSecondary: palette.Yellow20,
  brandSecondaryLight: palette.Yellow10,
  // CHARCOAL
  // eslint-disable-next-line no-dupe-keys
  brandSecondaryLight: palette.gray100,
};

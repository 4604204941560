import React from "react";
import { MailOutlined } from "@ant-design/icons";
import CashremitButton from "@cashremit/button";
import { Box, Stack, Text } from "@chakra-ui/react";
import { AuthCardBlock } from "./AuthCard.style";


const AuthCard = ({ onEmailReSend }) => {
    // const history = useHistory();
    // const [showUpdateDocuments, setShowUpdateDocuments] = useState(false);

    // const currentUser = JSON.parse(localStorage.getItem("user"));
    // const onReverifyDocs = () => {
    //     setShowUpdateDocuments(true);
    // };

    // const [isModalVisible, setIsModalVisible] = useState(true);

    // const toggleModal = () => {
    //     setIsModalVisible(!isModalVisible);
    // };

    return (
        <>
            <AuthCardBlock title="Check your inbox" bordered={false}>
                <Box mb={5}>
                    <Box
                        alignItems={"center"}
                    >
                        <Box className="reminder-text mb-5">
                            <div className="card-icon center" style={{ fontSize: '5rem' }}>
                                <MailOutlined />
                            </div>
                            <Text fontSize="md" color="gray.500">
                                We have sent you an email with a link for confirmation
                            </Text>
                        </Box>
                        <Box className="right-side">
                            <Stack
                                direction={["column", "column", "column", "column", "row"]}
                                spacing={5}
                            >
                                <CashremitButton type="submit" onClick={onEmailReSend}>
                                    Resend
                                </CashremitButton>
                            </Stack>
                        </Box>
                        {/* <Box className="right-side" sx={{ mt: 5 }}>
                            <Stack
                                direction={["column", "column", "column", "column", "row"]}
                                spacing={5}
                            >
                                <CashremitButton type="submit" onClick={onReverifyDocs}>
                                    Reverify Documents
                                </CashremitButton>

                            </Stack>
                        </Box> */}
                    </Box>
                </Box>
            </AuthCardBlock>
            {/* {showUpdateDocuments && (
                <UpdateDocumentsCard isModalVisible={isModalVisible} toggleModal={toggleModal} />
            )} */}
        </>
    );
};

export default AuthCard;

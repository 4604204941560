export const Capitalize = (str, from = "-", to = "") => {
  str = str.split(from);
  if (str) {
    for (var i = 0, x = str.length; i < x; i++) {
      str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }
  }
  return str.join(to);
};

export const isCountryNeedShortCode = (code) => {
  console.log({ code });
  switch (code) {
    case "GB":
    case "GH":
      return true;

    default:
      return false;
  }
};

export const removeSpace = (str = '') => {
  return str.replace(/[\s\W]/g, '');
}

export const isMobileOrTablet = () => {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}

export const randomNumber = (min = 0, max = 1) => {
  var random = Math.random()
  return Math.floor(random * (max - min) + min)
}

export const randomString = (length, allowedChars = null) => {
  var result = '';
  var characters = allowedChars || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export const userName = (firstname, lastname = '', joiner = '.') => {
  let result;
  result = (firstname ? firstname : randomString(16)) + joiner + lastname + randomNumber(100000, 999999)
  result = result.toString().replace(/'/g, "");
  result = result.replace(/ /g, "");
  return result;
}

export const slugify = (string) => {
  string = string || "";
  // eslint-disable-next-line no-useless-escape
  return string.replace(/ /g, '-').replace(/[^\一-龠\ぁ-ゔ\ァ-ヴー\w\.\-]+/g, '');
};

export const emailGenerator = (username) => {
  let provider = ['gmail.com', 'yahoo.com', 'outlook.com', 'icloud.com'];
  return `${slugify(username)}`.toLowerCase() + '@' + provider[randomNumber(0, provider.length - 1)];
}

export const serialize = function (obj, prefix) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push((v !== null && typeof v === "object") ?
        serialize(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}
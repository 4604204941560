import React from "react";
import {
  ReferFriendWrapper,
  LeftTextWrapper,
  ButtonWrapper,
  RightImageWrapper,
} from "./ReferFriend.style";
import Button from "../button";
import { Image } from "antd";
import { Grid } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import ResetSvg from "assets/svg/ResetSvg";

const handler = () => {
  console.log("Refer friend click");
};

const ReferFriend = ({ classToggle }) => {
  return (
    <ReferFriendWrapper classToggle={classToggle}>
      <Grid margin="0">
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacingX="40px"
          spacingY="20px"
        >
          <Box sx={{ display: "inline-block", width: "100%" }}>
            <RightImageWrapper>
              <Box
                sx={{
                  display: "inline-block",
                  width: "100%",
                  position: "relative",
                }}
              >
                <Image src="/assets/referFriendNew.png" preview={false} />

                <Box
                  sx={{
                    position: "absolute",
                    padding: "20px",
                    background: "white",
                    borderRadius: "8px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                  }}
                  bottom={{ base: "20px", lg: "30px" }}
                  right={{ base: "20px", lg: "-70px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      w: "36px",
                      h: "36px",
                      borderRadius: "50px",
                      background: "rgba(48, 81, 120, 0.1)",
                      marginBottom: "24px",
                    }}
                  >
                    <ResetSvg />
                  </Box>

                  <Heading
                    as="h6"
                    fontSize={{ base: "16px", md: "18px" }}
                    sx={{ color: "#305178", fontWeight: 300, mb: "10px" }}
                  >
                    Your friend referral
                  </Heading>

                  <Heading
                    as="h4"
                    fontSize={{
                      base: "18px",
                      md: "24px",
                      lg: "32px",
                    }}
                    sx={{
                      color: "#305178",
                      fontWeight: 600,
                      mb: "12px",
                    }}
                  >
                    ￡15 GBP
                  </Heading>

                  <Box sx={{ display: "flex" }}>
                    <Link to="/">
                      <Box
                        sx={{
                          color: "#305178",
                          fontSize: "14px",
                          textDecoration: "underline",
                        }}
                      >
                        Click Now
                      </Box>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </RightImageWrapper>
          </Box>
          <Box sx={{ display: "inline-block", width: "100%" }}>
            <LeftTextWrapper>
              <h6>invite your friend</h6>
              <h1>
                Refer a friend and earn £15 towards your next money transfer
              </h1>
              <p>
                Earn extra income when you share your referral code to a friend,
                once the completed their first transfer, you will earn your
                first income. Log into you account from the account setting you
                can start sharing your referral code.
              </p>

              <Box
                sx={{ display: "flex", alignItems: "center", gap: "25px" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Link to="/signup">
                  <ButtonWrapper>
                    <Button
                      onClick={handler}
                      width={"0%"}
                      fontSize={"20px"}
                      fontWeight={"500"}
                    >
                      Sign up now
                    </Button>
                  </ButtonWrapper>
                </Link>
                <a href="/terms-and-conditions" target="_bank">
                  *terms & conditions apply
                </a>
              </Box>
            </LeftTextWrapper>
          </Box>
        </SimpleGrid>
      </Grid>
    </ReferFriendWrapper>
  );
};

export default ReferFriend;

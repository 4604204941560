/* eslint-disable import/no-anonymous-default-export */
import axios from "../axios";

export default {
  getProfileData() {
    return axios.get("/users/profile");
  },
  addCloseAccountRequest(params) {
    return axios.post("/users/support/ticket", params);
  },
  resendEmail(payload) {
    return axios.post("/resend_email_otp", payload)
  },
  // doc verification
  sendDocVerificationLink() {
    return axios.post("/users/send-doc-verification-link");
  },
  getCountryCodes(configurationName = "Identity Verification") {
    return axios.post("/users/trulioo/countrycodes", { configurationName });
  },
  updateProfileData(payload) {
    const formdata = new FormData();
    const { proof_id, proof_address, gbg_response, gbg_extracted_fields, ...reqData } = payload;
    Object.keys(reqData).map((k) =>
      formdata.append(k, reqData[k])
    );
    if (proof_id) formdata.append("proof_id", proof_id);
    if (proof_address) formdata.append("proof_address", proof_address);
    if (gbg_response) formdata.append("gbg_response", JSON.stringify(gbg_response));
    if (gbg_extracted_fields) formdata.append("gbg_extracted_fields", JSON.stringify(gbg_extracted_fields));
    return axios.put("/users/profile-web", formdata);
  },

  gbgVerification(payload) {
    const formdata = new FormData();
    const { proof_id, proof_address, gbg_response, gbg_extracted_fields, ...reqData } = payload;
    Object.keys(reqData).map((k) =>
      formdata.append(k, reqData[k])
    );
    // if (proof_id) formdata.append("proof_id", proof_id);
    // if (proof_address) formdata.append("proof_address", proof_address);
    if (gbg_response) formdata.append("gbg_response", JSON.stringify(gbg_response));
    if (gbg_extracted_fields) formdata.append("gbg_extracted_fields", JSON.stringify(gbg_extracted_fields));
    return axios.post("/users/gbg-verification", formdata);
  },

  updateUUID(payload) {
    return axios.put("/users/uuid", payload)
  },
  // updateProfileData(payload) {
  //   return axios.post("/users/one-profile/update", payload);
  // },
  documentVerification(payload = {}) {
    return axios.post("/users/trulioo/verify", payload);
  },
  emailVerify(payload) {
    return axios.post("/email_verify", payload)
  }
};

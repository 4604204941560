/* eslint-disable import/no-anonymous-default-export */
export const space = {
  none: "0px",
  xxxs: "2px",
  xxs: "4px",
  xs: "8px",
  sm: "12px",
  md: "16px",
  lg: "24px",
  xl: "32px",
  xxl: "40px",
  xxxl: "48px",
};

export const layout = {
  xxs: "16px",
  xs: "24px",
  sm: "32px",
  md: "48px",
  lg: "64px",
  xl: "96px",
  xxl: "160px",
};

export const radius = {
  xs: "1px",
  sm: "2px",
  md: "4px",
  lg: "8px",
};

export default {
  space,
  layout,
  radius,
};

import React from "react";
import { Spin } from "antd";

const Loading = () => {
  return (
    <div className="position-relative">
      <div
        className="w-100 d-flex text-center align-items-center justify-content-center position-absolute fixed-top"
        style={{ height: "calc(100vh - 150px)", zIndex: "9999" }}
      >
        <Spin size="large" />
      </div>
    </div>
  );
};

export default Loading;

/* eslint-disable import/no-anonymous-default-export */
import axios from "../axios";

export default {
  getrateChangeHistory({from, to, from_country_id, to_country_id}) {
    return axios.get(
      `/users/rate-change/history?from=${from}&to=${to}&from_country_id=${from_country_id}&to_country_id=${to_country_id}`
    );
  },
};

import React, { useState, useEffect, useContext } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
    Box,
    Heading,
    Input,
    SimpleGrid,
    Text
} from "@chakra-ui/react";
import { Button as AntButton, Checkbox, DatePicker, Form, Modal, Radio, Upload, message } from "antd";
import AuthCard from "components/Dashboard/AuthCard/AuthCard";
import UpdateDocumentsCard from "components/Dashboard/updateDocuments/UpdateDocumentsCard";
import Loader from "containers/Loader/Loader";
import { Context } from "helpers/context/Store";
import moment from "moment";
import { FaUser } from "react-icons/fa";
import { PiAddressBook } from "react-icons/pi";
import { useHistory } from "react-router-dom";
import Button from "../../@cashremit/button";
import { settingsAPI } from "../../http/api";
import LocationSearchInput from "../AccountSettings/LocationSearchInput";
import "../Modals/CustomeModal.scss";
import CreateSupportTicket from "../NeedHelp/CreateSupportTicket";

const UserInfo = ({
    label,
    value,
    icon,
    verifiedText,
    name,
    disabled = false,
}) => (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Text sx={{ fontSize: "12px", fontWeight: 400, mb: "10px" }}>
                {label}
            </Text>
            {verifiedText && (
                <Text
                    sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        mb: "10px",
                        color: verifiedText.color,
                    }}
                >
                    {verifiedText.text}
                </Text>
            )}
        </Box>
        <Form.Item
            name={name}
            hasFeedback
            rules={[
                {
                    required: true,
                    message: "This field is required",
                },
            ]}
        >
            <Input
                {...(disabled ? { defaultValue: value } : {})}
                sx={{
                    fontSize: "14px",
                    color: "rgba(0, 0, 0, 0.90)",
                    borderColor: "#1A4975",
                }}
                name={name}
                disabled={disabled}
            />
        </Form.Item>
    </Box>
);

const AccountSettings = (props) => {
    const [context] = useContext(Context);

    const [form] = Form.useForm();
    const history = useHistory();


    const [profile, setProfile] = useState(
        JSON.parse(localStorage.getItem("user"))
    );
    const [closeAccount, setCloseAccount] = useState(false);
    const [open, setOpen] = useState(true)
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState("Not Specified");
    const [date, setDate] = useState(null);
    const [state, setState] = useState("");
    const isOpen = () => {
        setOpen(!open)
    }

    const changeLocation = (address) => {
        setState(`${address.address1},${address.address2},${address.city},${address.country}`);
    };

    const extractFromAddress = (components, type) => {
        return (
            components
                .filter((component) => component.types.includes(type))
                .map((item) => item.short_name)
                .pop() || null
        );
    };

    const getParsedAddress = (address) => {
        const address1 = `${extractFromAddress(address, "premise") ||
            ""} ${extractFromAddress(address, "street_number") ||
            ""} ${extractFromAddress(address, "route") || ""}`.trim();
        const address2 = `${extractFromAddress(address, "neighborhood") ||
            ""} ${extractFromAddress(address, "sublocality_level_3") ||
            ""} ${extractFromAddress(address, "sublocality_level_2") ||
            ""} ${extractFromAddress(address, "sublocality_level_1") || ""}
    `.trim();
        const room = extractFromAddress(address, "room");
        const floor = extractFromAddress(address, "floor");
        const subpremise = extractFromAddress(address, "subpremise");
        const premise = extractFromAddress(address, "premise");
        const street_number = extractFromAddress(address, "street_number");
        const street_name = extractFromAddress(address, "route");
        const city = extractFromAddress(address, "administrative_area_level_2");
        const suburb = extractFromAddress(address, "locality");
        const state_province_code = extractFromAddress(
            address,
            "administrative_area_level_1"
        );
        const country = extractFromAddress(address, "country");
        const post_code = extractFromAddress(address, "postal_code");
        return {
            room,
            floor,
            subpremise,
            premise,
            street_number,
            street_name,
            address1,
            address2,
            suburb,
            city,
            state_province_code,
            country,
            post_code,
        };
    };

    const onLocationChange = (address) => {
        const parsedAddress = getParsedAddress(address.address_components);
        console.log(parsedAddress, "parsedAddress");
        form.setFieldsValue(parsedAddress);
        changeLocation(parsedAddress);
    };


    useEffect(() => {
        settingsAPI.getProfileData().then(({ data }) => {
            setProfile(data.data);
            setDate(data.data.dob ? moment(data.data.dob) : null);
            setValue(data.data.gender);
            console.log(data, "finalData")
            localStorage.setItem("user", JSON.stringify(data.data));
        });
        console.log(profile, "profile")
        setLoading(false)
        return () => { };
        // eslint-disable-next-line
    }, []);

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const onEmailReSend = () => {
        settingsAPI.resendEmail({ email: context?.user?.email }).then(({ data }) => {
            message.success("email send successfully");
        }).catch((err) => {
            message.error(err.message);
        })
    }

    const isEmailVerified = JSON.parse(
        localStorage.getItem("user")
    )?.is_email_verify;

    const isEmailSent = JSON.parse(
        localStorage.getItem("user")
    )?.is_email_sent;

    const isDocVerified = JSON.parse(
        localStorage.getItem("user")
    )?.is_doc_verify;

    const isSubmited = JSON.parse(
        localStorage.getItem("user")
    )?.is_doc_submitted;


    const onSubmitHandler = (v) => {
        setLoading(true);
        settingsAPI
            .addCloseAccountRequest({ ...v, closeAccount: true })
            .then(({ data }) => {
                message.success(data.message + ` #${data.data.ticket_number}`);
                setCloseAccount(!closeAccount);
            })
            .catch((err) => {
                message.error(err.message);
            }).finally(() => {
                setLoading(false);
            });
    };

    const updateProfileData = (v) => {
        console.log(v, "v")
        console.log(v?.proof_address, "proof_address")
        setLoading(true);
        settingsAPI
            .updateProfileData({
                ...v, dob: date, _id: profile._id, gender: value, gbg_token: localStorage.getItem("GBG_access_token"),
                proof_address: v?.proof_address?.[0]?.originFileObj, street_name: state
            })
            .then(({ data }) => {
                console.log(data, "data")
                localStorage.setItem("user", JSON.stringify(data.data));
                message.success(data.message);
                history.push('/settings')
            })
            .catch((err) => {
                message.error(err.message);
            }).finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        console.log("profile", profile)
        form.resetFields();
        // eslint-disable-next-line
    }, [profile])

    const [isModalVisible, setIsModalVisible] = useState(true);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) :
                (
                    isEmailSent && !isEmailVerified && isModalVisible ? (
                        <Modal
                            title="Email Resend"
                            visible={isModalVisible}
                            onCancel={toggleModal}
                            footer={null}
                            className="custom-modal"
                            style={{ top: 0, right: 0, left: 0, position: 'fixed', minWidth: '-webkit-fill-available' }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <AuthCard isOpen={isOpen} onEmailReSend={onEmailReSend} />
                            </div>
                        </Modal>
                    ) :
                        !isSubmited && isModalVisible ? (
                            <Modal
                                title="Complete Documents verification"
                                visible={isModalVisible}
                                onCancel={toggleModal}
                                footer={null}
                                className="custom-modal"
                                style={{ top: 0, right: 0, left: 0, position: 'fixed', height: "fit-content" }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <UpdateDocumentsCard isModalVisible={isModalVisible} toggleModal={toggleModal} doc_status="false" />
                                </div>
                            </Modal>
                        )
                            :
                            <>
                                {closeAccount ? (
                                    <div className="help-n-support">
                                        <h1 className="primary-heading">Close Account Request</h1>
                                        <hr className="hr-line" />
                                        <CreateSupportTicket onSubmit={onSubmitHandler} />
                                    </div>
                                ) : (
                                    <Modal
                                        title="Complete Profile settings"
                                        open={true}
                                        closable={false}
                                        // onCancel={toggleModal}
                                        footer={null}
                                        className="custom-modal"
                                        style={{ top: 0, right: 0, left: 0, position: 'fixed', minWidth: '-webkit-fill-available' }}
                                    >
                                        <Box>
                                            <SimpleGrid
                                                columns={{ base: 1, lg: 5 }}
                                                spacing={10}
                                                width={{ base: "100%", sm: "80%", md: "70%", lg: "60%", xl: "40%" }}
                                                sx={{ mb: "20px", display: "flex", justifyContent: "center", margin: "auto", mt: "20px", boxShadow: " 0 0 20px rgba(0, 0, 0, 0.08)" }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "inline-block",
                                                        w: "100%",
                                                        padding: "30px",
                                                        bg: "#fff",
                                                        borderRadius: "12px",
                                                        gridRow: "1 / 4",
                                                        gridColumn: { base: "1", lg: "1 / 4" },
                                                    }}
                                                >
                                                    {context?.user && (
                                                        <>
                                                            <Heading
                                                                as="h5"
                                                                sx={{ fontWeight: 600, mb: "15px" }}
                                                                fontSize={{ base: "18px" }}
                                                            >
                                                                Profile settings
                                                            </Heading>
                                                            {/* <IDVerificationSuccess /> */}
                                                            <Form
                                                                name="profile"
                                                                onFinish={updateProfileData}
                                                                onFinishFailed={() => { }}
                                                                form={form}
                                                                initialValues={{
                                                                    // fname: context?.user?.first_name,
                                                                    first_name: profile?.first_name,
                                                                    last_name: profile?.last_name,
                                                                    email: context?.user?.email,
                                                                    mobile: context?.user?.mobile_no,
                                                                    occuption: profile?.occuption,
                                                                    source_country_id: profile?.source_country_id,
                                                                    street_name: profile?.street_name,
                                                                    post_code: profile?.post_code,
                                                                    city: profile?.city,
                                                                    country: profile?.country,
                                                                    gender: profile?.gender,
                                                                    nationality: profile?.nationality,

                                                                }}
                                                            >
                                                                <SimpleGrid
                                                                    gridTemplateColumns={"repeat(2, 1fr)"}
                                                                    columnGap={6}
                                                                >

                                                                    <UserInfo
                                                                        label="First Name"
                                                                        value={`${profile?.first_name || ""}`}
                                                                        icon={<FaUser color="#A0AEC0" />}
                                                                        name={"first_name"}
                                                                    />
                                                                    <UserInfo
                                                                        label="Last Name"
                                                                        value={`${profile?.last_name || ""}`}
                                                                        // value={`${context?.user?.first_name || ""} ${context?.user?.last_name || ""
                                                                        //   }`}
                                                                        icon={<FaUser color="#A0AEC0" />}
                                                                        name={"last_name"}
                                                                    />
                                                                    <UserInfo
                                                                        label="Email"
                                                                        value={profile?.email}
                                                                        icon={<EmailIcon color="gray.400" />}
                                                                        verifiedText={{
                                                                            text: context?.user?.is_email_verify
                                                                                ? "(Verified)"
                                                                                : "(Not Verified)",
                                                                            color: context?.user?.is_email_verify
                                                                                ? "#389E0D"
                                                                                : "#CF1322",
                                                                        }}
                                                                        name={"email"}
                                                                    />

                                                                    <UserInfo
                                                                        label="Mobile Number"
                                                                        value={context?.user?.mobile_no}
                                                                        icon={<PhoneIcon color="gray.400" />}
                                                                        verifiedText={{
                                                                            text: profile?.is_mobile_verify
                                                                                ? "(Verified)"
                                                                                : "(Not Verified)",
                                                                            color: profile?.is_mobile_verify
                                                                                ? "#389E0D"
                                                                                : "#CF1322",
                                                                        }}
                                                                        name={"mobile"}
                                                                        disabled={true}
                                                                    />

                                                                    <Form.Item name="dob">
                                                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                                            <Text
                                                                                sx={{
                                                                                    fontSize: "12px",
                                                                                    fontWeight: 400,
                                                                                    mb: "10px",
                                                                                }}
                                                                            >
                                                                                Date of Birth
                                                                            </Text>

                                                                            <DatePicker
                                                                                format="DD/MM/YYYY"
                                                                                placeholder="DD/MM/YYYY"
                                                                                style={{
                                                                                    fontSize: "14px",
                                                                                    color: "rgba(0, 0, 0, 0.90)",
                                                                                    backgroundColor: "transparent",
                                                                                    borderColor: "#1A4975",
                                                                                    borderRadius: "5px",
                                                                                }}
                                                                                size="large"
                                                                                name="dob"
                                                                                value={date}
                                                                                onChange={handleDateChange}
                                                                            // defaultValue={profile?.dob ? moment(profile?.dob) : null}
                                                                            />
                                                                        </Box>
                                                                    </Form.Item>

                                                                    <UserInfo
                                                                        label="Occupation"
                                                                        icon={<FaUser color="#A0AEC0" />}
                                                                        name={"occuption"}
                                                                    />

                                                                    <Form.Item name="street_name">
                                                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                                            <Text
                                                                                sx={{
                                                                                    fontSize: "12px",
                                                                                    fontWeight: 400,
                                                                                    mb: "10px",
                                                                                }}
                                                                            >
                                                                                Full Address
                                                                            </Text>
                                                                            <LocationSearchInput
                                                                                onChange={onLocationChange}
                                                                                inputStyle={{
                                                                                    fontSize: "14px",
                                                                                    color: "rgba(0, 0, 0, 0.90)",
                                                                                    backgroundColor: "transparent",
                                                                                    borderColor: "#1A4975",
                                                                                    borderRadius: "5px",
                                                                                    paddingTop: "7px",
                                                                                    paddingBottom: "7px"
                                                                                }}
                                                                                addressValue={profile?.street_name}
                                                                            />
                                                                        </Box>
                                                                    </Form.Item>

                                                                    <UserInfo
                                                                        label="city"
                                                                        icon={<PiAddressBook />}
                                                                        name={"city"}
                                                                    />

                                                                    <UserInfo
                                                                        label="country"
                                                                        icon={<PiAddressBook />}
                                                                        name={"country"}
                                                                    />

                                                                    <UserInfo
                                                                        label="Postcode"
                                                                        icon={<FaUser color="#A0AEC0" />}
                                                                        name={"post_code"}
                                                                    />

                                                                    <UserInfo
                                                                        label="nationality"
                                                                        icon={<PiAddressBook />}
                                                                        name={"nationality"}
                                                                        value={`${profile?.nationality || ""}`}
                                                                        placeholder="Enter Nationality"
                                                                    />

                                                                    {!profile?.proof_address_image &&
                                                                        <Form.Item
                                                                            label="Upload address proof"
                                                                            name="proof_address"
                                                                            valuePropName="fileList"
                                                                            style={{ minWidth: 0 }}
                                                                            getValueFromEvent={(e) => {
                                                                                if (Array.isArray(e)) {
                                                                                    return e;
                                                                                }
                                                                                return e && e.fileList;
                                                                            }}
                                                                            // rules={[{ required: true, message: "Please upload your documents!" }]}
                                                                            labelCol={{ span: 24 }}
                                                                            wrapperCol={{ span: 24 }}
                                                                        >
                                                                            <Upload
                                                                                name="proof_address"
                                                                                maxCount={1}
                                                                                listType="text"
                                                                            >
                                                                                <AntButton icon={<UploadOutlined />}>Upload Image</AntButton>
                                                                            </Upload>
                                                                        </Form.Item>
                                                                    }

                                                                </SimpleGrid>

                                                                <SimpleGrid>
                                                                    <Form.Item name="gender">
                                                                        <Text
                                                                            sx={{
                                                                                fontSize: "12px",
                                                                                fontWeight: 400,
                                                                                mb: "10px",
                                                                            }}
                                                                        >
                                                                            Gender
                                                                        </Text>
                                                                        <Radio.Group onChange={onChange} value={value}>
                                                                            <Radio value={"Female"}>Female</Radio>
                                                                            <Radio value={"Male"}>Male</Radio>
                                                                            <Radio value={"Not Specified"}>Not Specified</Radio>
                                                                        </Radio.Group>
                                                                    </Form.Item>
                                                                </SimpleGrid>

                                                                <Form.Item
                                                                    name="toc"
                                                                    valuePropName="checked"
                                                                    className="cr-mb-1"
                                                                    style={{ marginTop: "20px" }}
                                                                    rules={[
                                                                        {
                                                                            validator: (_, value) =>
                                                                                value
                                                                                    ? Promise.resolve()
                                                                                    : Promise.reject(
                                                                                        "Should accept our terms, conditions and privacy policy"
                                                                                    ),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Checkbox>
                                                                        By proceeding, I agree to CashRemit{" "}
                                                                        <a href="/terms-and-conditions">Terms and Conditions</a>{" "}
                                                                        and <a href="/privacy-policy">Privacy Policy</a>
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                                    <Button
                                                                        width={"0%"}
                                                                        fontSize={"18px"}
                                                                        fontWeight={"600"}
                                                                        type={"submit"}
                                                                    >
                                                                        Save Changes
                                                                    </Button>
                                                                </Box>
                                                            </Form>
                                                        </>
                                                    )}
                                                </Box>

                                            </SimpleGrid>
                                        </Box>
                                    </Modal>
                                )}
                            </>
                )}
        </>
    );
};

export default AccountSettings;

import {
  layout as defaultLayout,
  space as defaultSpace,
  radius as defaultRadius,
} from "./config/sizing";
import defaultBreakpoints from "./config/breakpoints";
import defaultColors, { palette as defaultPalette } from "./config/colors";
import {
  typography as defaultTypography,
  fontSize as defaultFontSize,
  fontWeight as defaultFontWeight,
  lineHeight as defaultLineHeight,
  letterSpacing as defaultLetterSpacing,
} from "./config/typography";

export const themeOverride = (overrides = {}) => {
  const layout = { ...defaultLayout, ...overrides.layout };
  const space = { ...defaultSpace, ...overrides.space };
  const radius = { ...defaultRadius, ...overrides.radius };
  const breakpoints = { ...defaultBreakpoints, ...overrides.breakpoints };
  const colors = { ...defaultColors, ...overrides.colors };
  const palette = { ...defaultPalette, ...overrides.palette };
  const typography = { ...defaultTypography, ...overrides.typography };
  const fontSize = { ...defaultFontSize, ...overrides.fontSize };
  const fontWeight = { ...defaultFontWeight, ...overrides.fontWeight };
  const lineHeight = { ...defaultLineHeight, ...overrides.lineHeight };
  const letterSpacing = { ...defaultLetterSpacing, ...overrides.letterSpacing };

  return {
    name: "Cashremit",
    // Globals
    breakpoints,
    colors,
    layout,
    space,
    radius,
    typography,
    fontSize,
    fontWeight,
    lineHeight,
    letterSpacing,
    // Components
    button: {
      borderRadius: "3px",
      fontSize: fontSize.xxs,
      fontWeight: fontWeight.bold,
      lineHeight: lineHeight.md,
      padding: `${space.sm} ${space.lg}`,
      paddingSmall: `${space.xxs} ${space.xs}`,
      textColor: colors.Green100,
      backgroundColorHover: colors.brandSecondaryLight,
      ghostBorderSize: "2px",
    },
    // default
    input: {
      backgroundColor: colors.white,
      border: `1px solid ${colors.gray100}`,
      borderRadius: radius.sm,
      fontSize: fontSize.xs,
      lineHeight: lineHeight.md,
      padding: space.sm,
      textColor: colors.gray100,
      textColorDisabled: colors.gray100,
      backgroundColorDisabled: colors.gray100,
      backgroundColorDisabledLight: colors.gray100,
      borderBottomGhost: `1px solid ${colors.gray100}`,
      buttonWidth: space.xxl,
      placeholderColor: colors.textMuted,
    },
    section: {
      backgroundColor: {
        white: colors.white,
        brandDefault: colors.brandDefault,
        default: colors.white,
      },
    },
    select: {
      backgroundColor: colors.ui00,
      border: `1px solid ${colors.ui04}`,
      borderRadius: radius.sm,
      fontSize: fontSize.xs,
      lineHeight: lineHeight.md,
      padding: space.sm,
      textColor: colors.text01,
      textColorDisabled: colors.text02,
      backgroundColorDisabled: colors.ui02,
      backgroundColorDisabledLight: colors.ui04,
      borderBottomGhost: `1px solid ${colors.ui03}`,
    },
    palette,
  };
};

export default themeOverride();

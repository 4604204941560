/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import { AiOutlineAppstore } from "react-icons/ai";
import { FiDollarSign, FiGift, FiSettings } from "react-icons/fi";
import { MdOutlineHelpOutline } from "react-icons/md";
import { RiGroupLine } from "react-icons/ri";
import { Icon } from "@chakra-ui/react";

const IcoWrapper = styled.span`
  display: inline-flex;
  svg {
    font-size: 22px;
    position: relative;
  }
`;

const dashboardIcon = () => (
  <IcoWrapper>
    <Icon as={AiOutlineAppstore} />
  </IcoWrapper>
);
const transactionIcon = () => (
  <IcoWrapper>
    <Icon as={FiDollarSign} />
  </IcoWrapper>
);
const beneficiaryIcon = () => (
  <IcoWrapper>
    <Icon as={RiGroupLine} />
  </IcoWrapper>
);
const inviteIcon = () => (
  <IcoWrapper>
    <Icon as={FiGift} />
  </IcoWrapper>
);
const settingIcon = () => (
  <IcoWrapper>
    <Icon as={FiSettings} />
  </IcoWrapper>
);
const helpIcon = () => (
  <IcoWrapper>
    <Icon as={MdOutlineHelpOutline} />
  </IcoWrapper>
);

export default {
  menu: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: dashboardIcon,
    },
    {
      name: "Transaction",
      url: "/transactions",
      icon: transactionIcon,
    },
    {
      name: "Beneficiary",
      url: "/beneficiary",
      icon: beneficiaryIcon,
    },
    {
      name: "Invite a Friend",
      url: "/invite-friend",
      icon: inviteIcon,
    },
    {
      name: "Account Setting",
      url: "/settings",
      icon: settingIcon,
    },
    {
      name: "Need Help",
      url: "/need-help",
      icon: helpIcon,
    },
  ],
};

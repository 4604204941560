import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiChevronDown, FiSearch } from "react-icons/fi";

const CustomCountryDDNew = (props) => {
  const { data, onChange, defaultValue } = props;

  const [state, setState] = useState({
    selectedValue: data.find((d) => d.value === defaultValue),
  });
  useEffect(() => {
    if (!state.selectedValue) {
      setState((s) => ({
        selectedValue: data.find(() => true),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // console.log("data", data);

  const countryChangeHanler = (v) => {
    onChange(v);
    setState({ selectedValue: v });
  };

  const btnClickHandler = () => {
    let dd = document.getElementById(`custom-dd-${props.idx}`);
    let input = document.getElementById(`custom-input-${props.idx}`);
    dd.classList.add("show");
    input.focus();
  };

  const closeDD = () => {
    let dd = document.getElementById(`custom-dd-${props.idx}`);
    if (dd.classList.contains("show")) {
      setTimeout(() => {
        dd.classList.remove("show");
      }, 250);
    }
  };

  const filterHandler = () => {
    var input, filter, div, a, i, txtValue;
    input = document.getElementById(`custom-input-${props.idx}`);
    filter = input.value.toUpperCase();
    div = document.getElementById(`custom-dd-${props.idx}`);
    a = div.getElementsByTagName("div");

    for (i = 0; i < a.length; i++) {
      txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = "";
      } else {
        a[i].style.display = "none";
      }
    }
  };

  return state.selectedValue ? (
    <>
      <Box
        sx={{
          display: "inline-block",
          position: "relative",
          w: "100%",
          h: "100%",
        }}
      >
        <Button
          variant="ghost"
          sx={{
            w: "100%",
            h: "100%",
            ":hover": { background: "transparent" },
            ":focus": {
              background: "transparent",
              boxShadow: "0px 0px 0px 1px #305178",
            },
          }}
          onClick={btnClickHandler}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 10 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
              <Image
                src={state.selectedValue.flag}
                alt="flag"
                boxSize={"30px"}
                sx={{ borderRadius: "50px" }}
                objectFit="cover"
              />
              <Text sx={{ margin: "auto" }}>{state.selectedValue.name}</Text>
            </Box>

            <Box sx={{ display: "flex", marginRight: "30px", alignItems: "center" }}>
              <FiChevronDown />
            </Box>
          </Box>
        </Button>

        <Box
          sx={{
            position: "absolute",
            width: "320px",
            top: "-1px",
            left: "-170px",
            backgroundColor: "#fff",
            border: "1px solid #ededed",
            zIndex: 11,
            boxShadow: "rgb(0 0 0 / 26%) 0px 12px 16px",
            display: "none",
            overflow: "auto",
            "&.show": {
              display: "block",
              overflow: "hidden",
            },
          }}
          id={`custom-dd-${props.idx}`}
        >
          <InputGroup pt="2">
            <InputLeftElement
              top={2}
              pointerEvents="none"
              children={<FiSearch />}
            />
            <Input
              type="text"
              placeholder="Search..."
              id={`custom-input-${props.idx}`}
              onKeyUp={filterHandler}
              onBlur={closeDD}
              tabIndex="1"
              autoFocus
              marginRight={2}
              marginLeft={2}
              borderColor="#1a4975! important"
              boxShadow="none! important"
            />
          </InputGroup>

          {data &&
            data?.map((c, i) => (
              <Box
                sx={{
                  display: "flex",
                  padding: "12px",
                  cursor: "pointer",
                  h: "60px",
                  ":hover": {
                    border: "solid 1px #1a4975",
                    borderRadius: "8px",
                    margin: "7px",
                    height: "43px",
                  },
                }}
                key={i}
                onClick={() => countryChangeHanler(c)}
                className={
                  c.value === state.selectedValue?.value ? "selected" : ""
                }
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Image
                    src={c.flag}
                    alt="flag"
                    boxSize={"30px"}
                    sx={{ borderRadius: "50px" }}
                    objectFit="cover"
                  />
                  <Text
                    sx={{ fontSize: "16px", fontWeight: 500, color: "#305178" }}
                  >
                    {c.name}
                  </Text>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </>
  ) : (
    <></>
  );
};

export default CustomCountryDDNew;

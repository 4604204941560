import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  PageHeader,
  Badge,
  Layout,
  Input,
  Dropdown,
  Menu,
  Button,
  Avatar,
  Drawer,
} from "antd";
import { LeftOutlined, UserOutlined, SmileOutlined } from "@ant-design/icons";
import { RiMenu4Fill } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Box, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import moment from "moment";
import { LineSeperator } from "../globals/layout";
import menu from "./menu";
import { GrUser, GrClose, GrNotification } from "react-icons/gr";
import { FiBell } from "react-icons/fi";
import { Context } from "../../helpers/context/Store";
import notificationAPI from "../../http/api/notification";
import authAPI from "../../http/api/auth";
import { useRouter } from "../../helpers/hooks";
import styled from "styled-components";
const { Header } = Layout;
//const { Search } = Input;

const ClientHeader = (props) => {
  const router = useRouter();
  const { pathname } = router;

  const [search, setSearch] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [state, dispatch] = useContext(Context);

  const getNotifications = useCallback(() => {
    notificationAPI
      .getNotificationList()
      .then(({ data }) => {
        dispatch({ type: "SET_NOTIFICATIONS", payload: data.data });
      })
      .catch((err) => {
        console.warn(err);
        dispatch({ type: "SET_ERROR", payload: err });
      });
  }, [dispatch]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const logout = () => {
    localStorage.clear();
    dispatch({ type: "REMOVE_USER", payload: null });
    router.push("/");
  };

  const isEmailVerified = JSON.parse(
    localStorage.getItem("user")
  )?.is_email_verify;

  const getUser = useCallback(() => {
    authAPI
      .validateToken(localStorage.getItem("utoken"))
      .then(({ data }) => {
        const userData = data.data;
        userData.dob = userData.dob ? moment(userData.dob) : null;
        userData.expiry_date = userData.expiry_date
          ? moment(userData.expiry_date)
          : null;
        dispatch({ type: "SET_USER", payload: userData });

      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const notificationClickHandler = (notification) => {
    notificationAPI
      .markAsReadNotification(notification._id)
      .then(({ data }) => {
        dispatch({ type: "REMOVE_NOTIFICATION", payload: data.data._id });
      })
      .catch((err) => {
        console.warn(err);
        dispatch({ type: "SET_ERROR", payload: err });
      });
    props.history.push(`/transactions?id=${notification.moduleId}`);
  };

  const userMenu = (
    <Menu className="header-DD" style={{ padding: "15px", width: "162px" }}>
      {menu.menu.map((m, i) => (
        <>
          <Menu.Item key={m.url} onClick={() => props.history.push(m.url)} disabled={!isEmailVerified}>
            <div>{m.name}</div>
          </Menu.Item>
          <Menu.Divider />
        </>
      ))}
      <Menu.Item style={{ marginTop: "25px" }}>
        <Button type="link" onClick={logout}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  const notificationMenu = () => (
    <Drawer
      title={
        <span style={{ fontSize: "24px", fontWeight: 600 }}>Notifications</span>
      }
      placement="right"
      width="400px"
      onClose={closeDrawer}
      open={openDrawer}
    >
      {!state.error && state.notifications && state.notifications.length > 0 ? (
        state.notifications.map((notification) => (
          <div
            key={notification._id}
            onClick={() => notificationClickHandler(notification)}
          >
            <p style={{ padding: "8px 0" }}>
              <UserOutlined /> {notification.message}
            </p>
          </div>
        ))
      ) : (
        <div key={`no-msg`}>
          <p>
            <SmileOutlined /> No new notification !
          </p>
        </div>
      )}
    </Drawer>
  );

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const DropdownMenu = () => {
    return (
      <Dropdown
        key="user"
        className="user-DD"
        style={{ float: "right" }}
        overlay={userMenu}
        placement="bottomCenter"
        arrow
      >
        <i
          style={{
            cursor: "pointer",
            fontSize: "20px",
            marginLeft: "10px",
            color: "#1A4975",
            display: "flex",
          }}
        >
          <MdKeyboardArrowDown />
        </i>
      </Dropdown>
    );
  };

  const DropdownMenuSendMoney = () => {
    return (
      <Dropdown
        key="user"
        className="user-DD"
        style={{ float: "right" }}
        overlay={userMenu}
        placement="bottomCenter"
        arrow
      >
        <i
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: "#1A4975",
            display: "flex",
          }}
        >
          <GrUser />
        </i>
      </Dropdown>
    );
  };

  // const toggleTopSearchbar = () => {
  //   const body = document.querySelector("body");
  //   body.classList.toggle("searchbar-show");
  //   if (body.classList.contains("searchbar-show"))
  //     document.querySelector(".header-search").childNodes.item(1).focus();
  //   props.search("");
  //   setSearch("");
  // };

  const removeTopSearchbar = () => {
    const body = document.querySelector("body");
    if (body.classList.contains("searchbar-show"))
      body.classList.remove("searchbar-show");
  };

  const IcoWrapper = styled.span`
    display: inline-flex;
    cursor: pointer;
    svg {
      font-size: 22px;
      position: relative;
    }
  `;
  return (
    <div>
      {["/send-money", "/poli/callback", "/kaasi/callback"].includes(
        pathname
      ) ? (
        <PageHeader
          className="site-page-header"
          backIcon={<LeftOutlined />}
          onBack={() => props.back()}
          title="Send Money"
          extra={[
            <DropdownMenuSendMoney key="user" />,
            <Dropdown
              overlay={notificationMenu}
              key="notification"
              trigger={["click"]}
            >
              <Badge
                key="notification"
                count={
                  state.notifications.length > 9
                    ? "9+"
                    : state.notifications.length
                }
              >
                <i
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                    paddingRight: "10px",
                    lineHeight: "1px",
                    borderRight: "1px",
                    display: "flex",
                    marginLeft: "12px",
                  }}
                >
                  <GrNotification size={20} />
                </i>
              </Badge>
            </Dropdown>,
            <LineSeperator key="ln" />,
            <i
              style={{
                cursor: "pointer",
                fontSize: "20px",
                marginLeft: "12px",
                display: "flex",
              }}
              key="1"
              onClick={() => props.history.push("/transactions")}
            >
              <GrClose />
            </i>,
          ]}
        />
      ) : (
        <Header className="page-header">
          <div
            className="header-left"
            onClick={() => props.setCollapsed(!props.collapsed)}
          >
            <IcoWrapper>
              <RiMenu4Fill />
            </IcoWrapper>
          </div>
          <div className="header-right">
            <InputGroup className="header-search">
              <InputLeftElement
                pointerEvents="none"
                onBlur={removeTopSearchbar}
                autoFocus={true}
                children={
                  <Box className="search-icon">
                    <Search2Icon color="#1A4975" />
                  </Box>
                }
                style={{ height: "100%" }}
              />
              <Input
                className="search-input"
                type="text"
                placeholder="Search for something"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  props.search(e.target.value);
                }}
              />
            </InputGroup>

            <Dropdown
              overlay={notificationMenu}
              key="notification"
            // trigger={["click"]}
            >
              <div className="notification-wrap" onClick={showDrawer}>
                <Badge
                  key="notification"
                  className="badge-icon"
                  colorScheme="red"
                  count={
                    state.notifications.length > 9
                      ? "9+"
                      : state.notifications.length
                  }
                  style={{
                    backgroundColor: "red",
                    width: "10px",
                    fontSize: "10px",
                  }}
                >
                  <i style={{ cursor: "pointer" }}>
                    {" "}
                    <FiBell />{" "}
                  </i>
                </Badge>
              </div>
            </Dropdown>

            <div
              style={{
                height: "44px",
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                marginTop: "5px",
                lineHeight: "8px",
              }}
            >
              <span
                style={{ height: "22px", fontSize: "14px", fontWeight: 700 }}
              >
                {props.fullname}
              </span>
              <span
                style={{ height: "20px", lineHeight: "15px", fontSize: "14px", fontWeight: 400 }}
              >
                CRN: {props.crn}
              </span>
            </div>

            <Avatar
              size={40}
              style={{
                marginLeft: "20px",
                // fontSize: "49px",
                backgroundColor: "#f0f0f0",
                color: "#0f0f0f",
                verticalAlign: "middle",
              }}
            >
              {props.fullname
                .split(" ")
                .map((n) => n.substr(0, 1))
                .join("")
                .substr(0, 2)}
            </Avatar>

            <span key="user" className="header-DD">
              <DropdownMenu key="user" />
            </span>
          </div>
        </Header>
      )}
    </div>
  );
};

export default ClientHeader;

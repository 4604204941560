export const FAQs = [
  {
    q: "Why is transfer taking a long time",
    a: `Incorrect recipient details,
      Be sure you have provided the correct recipient details are correct before completing your transfer`,
  },
  {
    q: "How do I cancel a transaction that I have made",
    a: "in the event that you would like to cancel a transfer you made, if you don't mind contact Remitzo as before long as conceivable. After a transaction is paid out we may not be able to recall it, so it is critical you contact us as before long as conceivable to limit this possibility. You can discover our contact details on our website",
  },
  {
    q: "How long does a refund take?",
    a: "Once the transaction has been cancelled, a refund ordinarily takes up to 7 working days to be credited back to you, depending on your receiving bank. This could, different depending on the bank provider. Before Remitzo process your refund you will need to submit are refund request form, signed and foward to helpcenter@remitzo.com.au",
  },
  {
    q: "What is identity verification?",
    a: "Under the financial service company law, we are required by law to confirm all of our clients. This policy handles the character confirmation. Regularly this includes collecting a duplicate of government issued ID from you. This can be upload from your account settings. You can also send your ID safely at any time on e-mail the Remitzo Client support team will verify your ID.",
  },
  {
    q: "Is there anything I need to know before I upload my ID?",
    a: "Remitzo require to verifcation your address, before upload your ID make sure you have a utitly bill that shows your current home address",
  },
  {
    q: "What types of ID can you accept?",
    a: "Government issued ID such as Passport, Drivers Lcience",
  },
];

export const categories = [
  {
    label: "Pending Transaction",
    value: "Pending Transaction",
  },
  {
    label: "New user registration",
    value: "New user registration",
  },
  {
    label: "Document Verification",
    value: "Document Verification",
  },
  {
    label: "Payment Refund",
    value: "Payment Refund",
  },
];

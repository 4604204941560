import React from "react";
import { DocumentUpdateCard } from "./DocumentCard.style";
import { Box, Text, Stack } from "@chakra-ui/react";
import { settingsAPI } from "http/api";
import { Modal, message } from "antd";
import CashremitButton from "@cashremit/button";

const DocumentCard = ({ handleChange, isModalVisible, toggleModal }) => {
  const sendEmailHandler = () => {
    let currentUser = localStorage.getItem("user");
    currentUser = JSON.parse(currentUser);
    console.log("this is current user", currentUser);
    if (currentUser.live_id_verification_session_id) {
      // history.push(`/verify/documents/${currentUser.live_id_verification_session_id}`);
      handleChange("verification", {
        id: currentUser.live_id_verification_session_id,
      });
    } else {
      settingsAPI
        .updateUUID()
        .then((r) => {
          localStorage.setItem("user", JSON.stringify(r.data.data));
          handleChange("verification", {
            id: currentUser.live_id_verification_session_id,
          });
          // history.push(`/verify/documents/${r.data.data.live_id_verification_session_id}`);
        })
        .catch((e) => {
          console.log(e);
          message.error("UUID not found !!");
        });
    }
  };

  return (
    <Modal
      title="Complete Documents verification"
      open={isModalVisible}
      onCancel={toggleModal}
      footer={null}
      className="custom-modal"
      closable={false}
      style={{
        top: 0,
        right: 0,
        left: 0,
        position: "fixed",
        minWidth: "-webkit-fill-available",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <DocumentUpdateCard title="Account Verification(KYC)" bordered={false}>
          <Box mb={5}>
            <Box alignItems={"center"}>
              <Box className="reminder-text mb-5">
                <Text fontSize="md" color="gray.500">
                  Thanks Making to remitzo We will More information to completed
                  account verification.
                  {/* Thank you for registering with Remitzo. To proceed, we need to
                  verify your ID. Please follow the steps to upload either your
                  UK Licence or International Passport. Thank you for your
                  cooperation. */}
                </Text>
              </Box>
              <Box className="right-side">
                <Stack
                  direction={["column", "column", "column", "column", "row"]}
                  spacing={5}
                >
                  <CashremitButton
                    type="submit"
                    onClick={() => sendEmailHandler()}
                  >
                    ID Verification
                  </CashremitButton>
                </Stack>
              </Box>
            </Box>
          </Box>
        </DocumentUpdateCard>
      </div>
    </Modal>
  );
};

export default DocumentCard;

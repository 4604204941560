import axios from "../axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getWalletBalance() {
    return axios.get("/users/wallet/balance");
  },

  getWalletHistory(pageSize = 1, limit = 1) {
    return axios.get(
      `/users/wallet/transactions?pageSize=${pageSize}&limit=${limit}`
    );
  },
};

/* eslint-disable import/no-anonymous-default-export */
import axios from "../axios";

export default {
  addReferralPromo(params) {
    return axios.post("/users/invite/referral", params);
  },
  getReferralPromo() {
    return axios.get("/users/invite/referral");
  },
  getIncome() {
    return axios.get("/users/invite/income");
  },
  sendPromoCode(params) {
    return axios.post("/users/invite/promo-code/send", params);
  },
  validatePromoCode(params) {
    return axios.post("/users/invite/promo-code/validate", params);
  },
  shortnerUrl(params) {
    return axios.post("/users/invite/shortner", params);
  }
};

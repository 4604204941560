import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { Input } from "antd";
const LocationSearchInput = (props) => {
  console.log(props.addressValue, "addressValue addressValue");
  const [state, setState] = useState({
    address: "",
  });
  const handleChange = (address) => {
    setState((s) => ({ ...s, address }));
  };

  const handleSelect = (address, placeId) => {
    setState((s) => ({ ...s, address }));
    geocodeByPlaceId(placeId).then(async (results) =>
      props.onChange({ ...results[0], ...(await getLatLng(results[0])) })
    );
  };
  useEffect(() => {
    if (props?.addressValue) {
      setState((s) => ({ ...s, address: props?.addressValue }));
    }
    // eslint-disable-next-line
  }, [props?.addressValue]);
  return (
    <PlacesAutocomplete
      value={state.address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <Input
            {...getInputProps({
              placeholder: "Search Places ...",
              className: "location-search-input",
              style: props?.inputStyle,
              // value: props?.addressValue,
              ...props.extra
            })}
          />
          <div
            className="autocomplete-dropdown-container w-100"
            style={{
              zIndex: 1,
              position: "absolute",
              boxShadow: "5px 5px 6px #ccc",
            }}
          >
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, i) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                  backgroundColor: "#fafafa",
                  cursor: "pointer",
                  padding: "5px 10px",
                }
                : {
                  backgroundColor: "#ffffff",
                  cursor: "pointer",
                  padding: "5px 10px",
                };
              return (
                <div
                  key={i}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span key={suggestion.id}>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

LocationSearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputStyle: PropTypes.object,
  addressValue: PropTypes.string
};

export default LocationSearchInput;

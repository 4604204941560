import React, { useState } from "react";
import Button from "@cashremit/button/Button";
import { Image } from "@chakra-ui/react";
import UpdateDocumentsCard from "components/Dashboard/updateDocuments/UpdateDocumentsCard";
import { useHistory } from "react-router-dom";
const IDVerificationFail = () => {
    const [showUpdateDocuments, setShowUpdateDocuments] = useState(false);
    const history = useHistory();
    const onReverifyDocs = () => {
        // setShowUpdateDocuments(true);
        history.push("/dashboard");
    };

    const [isModalVisible, setIsModalVisible] = useState(true);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    return (
        <>
            <div style={{ textAlign: "center", marginTop: "40px", width: "40%", margin: "auto", paddingTop: "50px" }}>
                <Image
                    src="/assets/protect.png"
                    sx={{ w: "15%", m: "auto", mb: "20px" }}
                />
                <h3>ID Verification is been processing</h3>
                <p style={{ fontSize: "18px", width: "80%", margin: "auto" }}>
                    Your ID verification process Thank you for upload your ID, We are now
                    processing your ID. and you will receive email from us if we need
                    additional information from you Hence if need any help our support
                    please contact <href style={{ color: "blue" }}>helpcenter@remitzo.com</href> been failed.
                </p>
                {/* <h2>Your ID verification process has been failed.</h2> */}
                <Button
                    type="primary"
                    shape="round"
                    style={{ width: "200px", marginTop: "30px" }}
                    className="btn-green"
                    onClick={onReverifyDocs}
                >
                    Continue
                </Button>
            </div>
            {showUpdateDocuments && (
                <UpdateDocumentsCard
                    isModalVisible={isModalVisible}
                    toggleModal={toggleModal}
                    doc_status="false"
                />
            )}
        </>
    );
};

export default IDVerificationFail;

import {
  Box,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Grid } from "react-flexbox-grid";
// import {} from "module";
import BankingSecurityCheckSvg from "assets/svg/BankingSecurityCheckSvg";
import { MdCheckCircle } from "react-icons/md";
import { Wrapper } from "../../../src/containers/IndexLayout/style";

const BankingSecurity = () => {
  return (
    <Wrapper>
      <Box sx={{ display: "inline-block", w: "100%" }}>
        <Grid>
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacingX="40px"
            spacingY="20px"
          >
            <Box sx={{ display: "flex", w: "100%" }}>
              <Box
                sx={{
                  display: "inline-block",
                  w: "100%",
                  position: "relative",
                }}
                maxW={{ base: "100%", lg: "86%" }}
              >
                <Image
                  src="/assets/banking-security.png"
                  alt="banking-security"
                  objectFit="cover"
                  boxSize="100%"
                  borderRadius="8px"
                />

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    p: "20px",
                    background: "white",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                    height: "fit-content",
                    w: "fit-content",
                    borderRadius: "8px",
                    position: "absolute",
                  }}
                  top={{ base: "20px", lg: "90px" }}
                  right={{ base: "20px", lg: "-40px" }}
                >
                  <BankingSecurityCheckSvg />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{ display: "flex", flexDirection: "column", w: "100%" }}
              alignItems={{ base: "center", lg: "flex-start" }}
            >
              <Heading
                as="h5"
                sx={{
                  fontWeight: 500,
                  color: "#305178",
                  mb: "16px",
                  textTransform: "uppercase",
                }}
                fontSize={{
                  base: "18px",
                  md: "20px",
                }}
              >
                Financial service Security
              </Heading>

              <Heading
                as="h4"
                fontSize={{
                  base: "28px",
                  md: "28px",
                  xl: "56px",
                }}
                sx={{
                  fontWeight: 700,
                  color: "#305178",
                  mb: "30px",
                  textTransform: "uppercase",
                }}
                textAlign={{ base: "center", lg: "left" }}
              >
                Secure Your money transfer
              </Heading>

              <Text
                sx={{
                  color: "#0F1F33",
                  fontWeight: 500,
                  mb: "40px",
                }}
                fontSize={{ base: "16px", md: "18px" }}
                textAlign={{ base: "center", lg: "left" }}
              >
                Securing your money transfer" involves taking steps and
                precautions to ensure the safety and protection of funds when
                sending or receiving money electronically. Remitzo take scurity
                first Here are some key elements of securing a money transfer,
              </Text>

              <List spacing={4}>
                <ListItem
                  fontSize={{
                    base: "16px",
                    md: "16px",
                  }}
                  sx={{
                    fontWeight: 600,
                    color: "#305178",
                  }}
                >
                  <ListIcon as={MdCheckCircle} color="#305178" />
                  Choose a Reputable Service Provider
                </ListItem>
                <ListItem
                  fontSize={{
                    base: "16px",
                    md: "16px",
                  }}
                  sx={{
                    fontWeight: 600,
                    color: "#305178",
                  }}
                >
                  <ListIcon as={MdCheckCircle} color="#305178" />
                  Ensure platform or service you're using robust encryption
                </ListItem>

                <ListItem
                  fontSize={{
                    base: "16px",
                    md: "16px",
                  }}
                  sx={{
                    fontWeight: 600,
                    color: "#305178",
                  }}
                >
                  <ListIcon as={MdCheckCircle} color="#305178" />
                  Account and transaction protection using 2-factor
                  authentication
                </ListItem>
              </List>
            </Box>
          </SimpleGrid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

export default BankingSecurity;

import React from "react";

export const data = {
  links_social_icons: [
    {
      title: "facebook",
      href: "https://www.facebook.com",
    },
    {
      title: "twitter",
      href: "https://twitter.com",
    },
    {
      title: "instagram",
      href: "http://www.instagram.com",
    },
  ],
  contactTitle: "SUPPORT",
  phone: <a href="tel:+441354707384">+44 1354 70 7384</a>,
  email: <a href="mailto:helpcenter@remitzo.com">helpcenter@remitzo.com</a>,

  links_column_one: {
    title: "ABOUT US",
    links: [
      {
        title: "Company",
        href: "/company",
      },
      {
        title: "Careers",
        href: "/careers",
      },
      {
        title: "Investors relations",
        href: "/investors-relations",
      },
      {
        title: "Terms and conditions",
        href: "/terms-and-conditions",
      },
      {
        title: "Privacy Policy",
        href: "/privacy-policy",
      },
    ],
  },
  links_column_two: {
    title: "QUICK LINK",
    links: [
      {
        title: "Sign up ",
        href: "/signup",
      },
      {
        title: "Login",
        href: "/login",
      },
      {
        title: "Check our Rate",
        href: "/",
      },
    ],
  },
  links_column_three: {
    title: "MORE LINK",
    links: [
      {
        title: "Invite a friend",
        href: "/invites",
      },
      {
        title: "Living Abroad",
        href: "/living-abroad",
      },
      {
        title: "Help and FAQ",
        href: "/help-and-resources",
      },
      {
        title: "Help with Payment",
        href: "/help-with-payid",
      },
      {
        title: "Check our Rate",
        href: "/",
      },
    ],
  },
};

export const testimonialData = [
  {
    content:
      "Great experience. Might have been poor in the past there's been a ton of improvement lately. Will def do business with them",
    username: "Ezinne",
    avatar:
      "https://icons-for-free.com/iconfiles/png/512/avatar+people+profile+user+icon-1320185001671922416.png",
  },
  {
    content:
      "You guys are the best..very efficient and fast...I even had to recommend your services to my friends, and they have been using it regularly.thanks for delivering our dollars.",
    username: "Enyinna",
    avatar:
      "https://icons-for-free.com/iconfiles/png/512/avatar+people+profile+user+icon-1320185001671922416.png",
  },
  {
    content:
      "Remitzo is the best and so reliable. Good rates, awesome customer service, quick delivery, I just love using them.",
    username: "Maduka",
    avatar:
      "https://icons-for-free.com/iconfiles/png/512/avatar+people+profile+user+icon-1320185001671922416.png",
  },
];

export const brandImg = "../assets/img/svg/bgImg.svg";
export const brandImgMobile =
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/hombanner1-mobile.png";
// export const brandImg =
//   "https://cashremitlogo.s3-us-west-2.amazonaws.com/Hombanner1.png";
export const marketTag = "/assets/img/homebanner1.png";

export const banks = [
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/FirstBank.png",
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/access-bank.jpg",
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/Stanbic-Bank-Logo.jpg",
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/fidelitybank.png",
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/gtbank-logo.jpeg",
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/union-bankg.png",
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/united-bank.png",
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/wema-bank.png",
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/zenith-logo1.jpg",
];

export const apps = [
  "https://cashremitlogo.s3.us-west-2.amazonaws.com/PCI-DSS.png",
  "https://cashremitlogo.s3.us-west-2.amazonaws.com/Amazon_Web_Services_logo_AWS.png",
  "https://cashremitlogo.s3-us-west-2.amazonaws.com/Image+20.svg",
];

export const demoData = [
  {
    heading: "Remitzo mobile app",
    title: "Start Your International Transfer",
    subtitle:
      "Remitzo Mobile App Cross-border payments platfrom, fast and easy",
  },
];

export const appdemo = "/assets/img/app-screens.png";

export const demoapps = [
  {
    image: "https://cashremitlogo.s3-us-west-2.amazonaws.com/Image+15.svg",
    url: "https://apps.apple.com/au/app/cashremit-money-transfer/id1213282431",
  },
  {
    image: "https://cashremitlogo.s3-us-west-2.amazonaws.com/Image+16.svg",
    url: "/",
  },
];

export const streamlineIcons = [
  // "AwardStar1",
  // "CurrencyDollarCircle",
  // "CurrencyEuroCircle"
  {
    iconName: "secureIcon",
    title: "SECURE",
    subtitle:
      "We follow a number of measures to protect your Remitzo account such as our standard data protection",
  },
  {
    iconName: "simpleIcon",
    title: "SIMPLE",
    subtitle:
      "Quick account setup while ensuring fast and reliable money transfer. We made it simple and user friendly",
  },
  {
    iconName: "fastIcon",
    title: "FAST",
    subtitle:
      "We payout in minutes, no hidden cost anywhere.. we process your transfer faster than ever before",
  },
];

export const links = [
  {
    title: "Business",
    href: "/businuss",
  },
  {
    title: "Need Help ?",
    href: "/need-help",
  },
  // {
  //   title: "Login",
  //   href: "/login",
  // },
];

export const auth_links = [
  {
    title: "Signup ?",
    href: "/signup",
  },
];

export const deliveryMethodFor = ["251", 251];

export const deliveryOptions = [
  {
    label: "Account",
    value: "ACCOUNT",
  },
  {
    label: "Cash Pickup",
    value: "CASHPICKUP",
  },
];

export const payWithPayIdContent = `<section class="article-info">
<div class="article-content">
  <div class="article-body">
    <h2>
      <span class="wysiwyg-color-blue120"
        >Instant Deposits with PayID!&nbsp;</span
      >
    </h2>
    <h4>
      <span class="wysiwyg-color-blue120"
        >Details are displayed on
        <strong>your Payment methods </strong> page.&nbsp;</span
      >
    </h4>
    <p class="wysiwyg-text-align-left">
      From your Dashboard, Select the <strong>PayID</strong> option from the
      Quick Deposit Menu.&nbsp;
    </p>
    <p>
      <img
        src="/assets/img/payid/payid-1.png"
        alt="payid-1.png"
      />
    </p>
    <p>&nbsp;</p>
    <p>
      Below is an
      <strong><span class="wysiwyg-color-red110">example only</span></strong>
      - Please log into to<strong>&nbsp;Your CashRemit Account</strong> and
      select the <strong>PayID</strong> option to view
      <strong>Your Details</strong>.&nbsp;
    </p>
    <p>&nbsp;</p>
    <h4>
      <span class="wysiwyg-color-blue120"
        >The second thing you will need is your Mobile Banking app or Internet
        Banking.</span
      >
    </h4>
    <p>
      <span class="wysiwyg-color-black"
        >When logged into your mobile banking or online banking, select the
        option to<strong> pay anyone.</strong></span
      >
    </p>
    <p>
      <span class="wysiwyg-color-black"
        >Select the option to add a <strong>New Payee</strong>&nbsp;and choose
        to pay someone with the&nbsp;<strong>email address</strong> option.
      </span>
    </p>
    <p>
      <span class="wysiwyg-color-black"
        >Fill in the email address field with the email address shown
        on<strong> your PayID deposit page</strong>.&nbsp;</span
      >
    </p>
    <p>
      Enter your
      <span class="wysiwyg-color-black"
        ><strong>Description/Comment&nbsp;</strong>(shown on your PayID
        deposit page)<strong>&nbsp;</strong></span
      >into your bank's description/reference field and complete your
      deposit.&nbsp;
    </p>
    <p>
      <span class="wysiwyg-color-red120"
        ><strong>Please Note -&nbsp;</strong></span
      >
    </p>
    <ul>
      <li>
        PayID deposits, especially if this is your first deposit or you have
        deposited a large amount, may be held by your bank or take 24 hours to
        clear.&nbsp;
      </li>
      <li>
        If the reference number is not enter correct your will get automatic refund back to
        your account.
      </li>
    </ul>
    <p>&nbsp;</p>
    <p>
      <span class="wysiwyg-color-red120"
        >Click below for full step by step instructions specific to your
        Bank.</span
      >
    </p>
    <p>
      <a href="/help-with-payid/anz" target="_blank" rel="noopener"
        >PayID deposits via ANZ</a
      >
    </p>
    <p>
      <a href="/help-with-payid/nab" target="_blank" rel="noopener"
        >PayID deposit via NAB</a
      >
    </p>
    <p>
      <a href="/help-with-payid/cba" target="_blank" rel="noopener"
        >PayID deposits via Commonwealth Bank</a
      >
    </p>
    <p>
      <a href="/help-with-payid/westpac" target="_blank" rel="noopener"
        >PayID deposits via Westpac</a
      >
    </p>
    <p>&nbsp;</p>
    <h4
      class="article-title"
      title="Which Financial Institutions/Banks support PayID deposits?"
    >
      <span class="wysiwyg-color-blue120"
        >Which Financial Institutions/Banks support PayID deposits?</span
      >
    </h4>
    <p>
      <span class="wysiwyg-color-black"
        >The majority of Australian banks and Financial Institutions will
        support PayID deposits to email addresses.&nbsp;</span
      >
    </p>
    <p>
      <span class="wysiwyg-color-black"
        >Please use the below link to find suitable financial
        institution/banks that support PayID deposits.&nbsp;</span
      ><span class="wysiwyg-color-blue120"
        ><br /><a href="https://nppa.com.au/find-an-institution/"
          >https://nppa.com.au/find-an-institution/</a
        >&nbsp;</span
      >
    </p>
    <p>&nbsp;</p>
    <h4><span class="wysiwyg-color-blue120 wysiwyg-font-size-large">Need more help?&nbsp;</span></h4>
				<p>Please submit your request via WhatsApp -&nbsp;<a class="c-link" href="https://api.whatsapp.com/send?phone=61488880931" target="_blank" rel="noopener noreferrer">https://CashRemit.com.au/whatsApp</a>
				</p>
    <p>
      or start a conversation with us via Live Chat and our team will provide
      full instructions and advice.
    </p>
    <p>&nbsp;</p>
  </div>

  <div class="article-attachments">
    <ul class="attachments"></ul>
  </div>
</div>
</section>
`;

export const payWithPayId = {
  anz: `<section class="article-info">
  <div class="article-content">
    <div class="article-body">
      <h2>
        <span class="wysiwyg-color-blue120"
          >Instant Deposits with PayID!&nbsp;</span
        >
      </h2>
      <p>
        We have provided example screenshots and instructions on how to make
        PayID deposits from ANZ. Please note -&nbsp; this is a guide only and
        your actual experience may vary.&nbsp;
      </p>
      <p>
        You will need your CashRemit PayID details from the PayID deposit page on
        your account. You will need to<strong> copy</strong> the CashRemit
        <strong>PayID Email Address </strong>and the
        <strong>Description/Reference</strong> displayed on your account.&nbsp;
      </p>
      <p>&nbsp;</p>
      <h4 id="h_88334b21-397b-4432-8654-8a9abf0c8cbb">
        <span class="wysiwyg-color-blue120">ANZ Mobile Banking App&nbsp;</span>
      </h4>
      <p>
        <span class="wysiwyg-color-black"
          >From your&nbsp;<strong>ANZ Mobile Banking App&nbsp;</strong></span
        >
      </p>
      <p>
        <span class="wysiwyg-color-black">Select <strong>Pay</strong></span>
      </p>
      <p>
        <span class="wysiwyg-color-black"
          ><img
            src="/assets/img/payid/anz/blobid0.png"
            alt="blobid0.png"
            width="262"
            height="61"
        /></span>
      </p>
      <p>
        Select <strong>+</strong> in the bottom right to add CashRemit's
        details.&nbsp;
      </p>
      <p>
        <img
          src="/assets/img/payid/anz/Screenshot_2020-03-30_at_1.05.01_PM.png"
          alt="Screenshot_2020-03-30_at_1.05.01_PM.png"
          width="58"
          height="46"
        />
      </p>
      <p>
        Select the <strong>Email</strong> option and add the email address shown
        on<strong> your PayID deposit page.&nbsp;</strong>
      </p>
      <p>
        <img
          src="/assets/img/payid/anz/blobid5.png"
          alt="blobid5.png"
          width="347"
          height="62"
        />
      </p>
      <p>To<strong> complete</strong> your deposit</p>
      <p>Enter the <strong>amount</strong> you wish to deposit</p>
      <p>
        Enter your <strong>Description/Reference</strong> in the
        <strong>Optional Message field.&nbsp;</strong>
      </p>
      <p>
        <img
          src="/assets/img/payid/anz/mceclip0.png"
          alt="mceclip0.png"
          width="438"
          height="350"
        />
      </p>
      <p>Select<strong> PAY</strong></p>
      <p class="wysiwyg-text-align-left">
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<img
          src="/assets/img/payid/anz/blobid9.png"
          alt="blobid9.png"
          width="254"
          height="33"
        />
      </p>
      <p>&nbsp;</p>
      <p>
        This should <strong>complete</strong> your Instant PayID Deposit.&nbsp;
      </p>
      <p>
        <span class="wysiwyg-color-red120"
          ><strong>Please Note -&nbsp;&nbsp;</strong></span
        >PayID deposits, especially if this is your first deposit or you have
        deposited a large amount, may be held by your bank or take 24 hours to
        clear.&nbsp;
      </p>
      <p>&nbsp;</p>
      <h4 id="h_ed1c3ba1-861c-42dd-87ed-32423cf20f27">
        <span class="wysiwyg-color-blue120">ANZ Internet Banking</span>
      </h4>
      <p>
        Once you log into your Online Banking Account, in the top left corner
        please select the <strong>Payments</strong> option.<br /><br /><img
          src="/assets/img/payid/anz/blobid10.png"
          alt="blobid10.png"
          width="444"
          height="56"
        />&nbsp;<br /><br />Select&nbsp;<span class="wysiwyg-color-black"
          ><strong> Pay Anyone Access</strong></span
        ><br /><br /><img
          src="/assets/img/payid/anz/blobid11.png"
          alt="blobid11.png"
          width="142"
          height="83"
        /><br /><br />In the "To" section please click the
        <strong>Select payee or add new</strong> button<br /><br /><img
          src="/assets/img/payid/anz/Screen_Shot_2018-11-28_at_3.29.25_pm.png"
          alt="Screen_Shot_2018-11-28_at_3.29.25_pm.png"
          width="387"
          height="143"
        /><br /><br />Select<strong> Add a new payee</strong><br /><br /><img
          src="/assets/img/payid/anz/blobid12.png"
          alt="blobid12.png"
          width="137"
          height="50"
        /><br /><br />Select the
        <strong>Email Address</strong> option.<br /><br /><img
          src="/assets/img/payid/anz/blobid13.png"
          alt="blobid13.png"
          width="90"
          height="79"
        /><br /><br />Enter CashRemit PayID email address shown on<strong>
          your PayID deposit page&nbsp;</strong
        >and press continue.
      </p>
      <p>Enter the <strong>amount</strong> you wish to deposit</p>
      <p>
        Enter your <strong>Description/Reference</strong>&nbsp;into the
        <strong>Optional Message field.&nbsp;</strong>
      </p>
      <p>
        Add your name only to the Your Name or Business Name field if
        required.&nbsp;
      </p>
      <p>Select <strong>Continue</strong></p>
      <p>
        <br /><img
          src="/assets/img/payid/anz/blobid0_1.png"
          alt="blobid0.png"
          width="413"
          height="264"
        />
      </p>
      <p>
        &nbsp;This should <strong>complete</strong> your Instant PayID
        Deposit.&nbsp;
      </p>
      <p>
        <span class="wysiwyg-color-red120"
          ><strong>Please Note -&nbsp;&nbsp;</strong></span
        >PayID deposits, especially if this is your first deposit or you have
        deposited a large amount, may be held by your bank or take 24 hours to
        clear.&nbsp;
      </p>
      <h4>&nbsp;&nbsp;</h4>
      <h4><span class="wysiwyg-color-blue120 wysiwyg-font-size-large">Need more help?&nbsp;</span></h4>
				<p>Please submit your request via WhatsApp -&nbsp;<a class="c-link" href="https://api.whatsapp.com/send?phone=61488880931" target="_blank" rel="noopener noreferrer">https://CashRemit.com.au/whatsApp</a>
				</p>
      <p>
        or start a conversation with us via Live Chat and our team will provide
        full instructions and advice.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>

    <div class="article-attachments">
      <ul class="attachments"></ul>
    </div>
  </div>
</section>
`,
  nab: `<section class="article-info">
<div class="article-content">
  <div class="article-body">
    <h2>
      <span class="wysiwyg-color-blue120"
        >Instant Deposits with PayID!&nbsp;</span
      >
    </h2>
    <p>
      We have provided example screenshots and instructions on how to make
      PayID deposits from NAB. Please note -&nbsp; this is a guide only and
      your actual experience may vary.&nbsp;
    </p>
    <p>
      You will need your CashRemit PayID details from the PayID deposit page on
      your account. You will need&nbsp; to<strong> copy</strong> the CashRemit
      <strong>PayID Email Address </strong>and the
      <strong>Description/Reference</strong> displayed on your account.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      &nbsp;<span class="wysiwyg-color-blue120">NAB Mobile Banking App</span>
    </p>
    <p>
      Click <strong>Transfer &amp; Pay</strong> in the bottom left hand
      corner, select an account you wish to transfer from and press
      <strong>Next.</strong> From here, select <strong>Pay anyone.</strong>
    </p>
    <p>
      <img
        src="/assets/img/payid/nab/blobid1.png"
        alt="blobid1.png"
        width="316"
        height="134"
      />
    </p>
    <p>Select <strong>Mobile/PayID</strong></p>
    <p>
      <strong
        ><img
          src="/assets/img/payid/nab/blobid3.png"
          alt="blobid3.png"
          width="313"
          height="153"
      /></strong>
    </p>
    <p>Select <strong>Email address</strong></p>
    <p>
      <strong
        ><img
          src="/assets/img/payid/nab/blobid4.png"
          alt="blobid4.png"
          width="312"
          height="248"
      /></strong>
    </p>
    <p>
      Enter the email address shown on<strong>
        your PayID deposit page.&nbsp;</strong
      >
    </p>
    <p>Select<strong> Continue&nbsp;</strong></p>
    <p>Enter the&nbsp;<strong>Amount</strong>&nbsp;to deposit</p>
    <p>
      Enter your <strong>Description/Reference</strong> in the
      <strong>Detailed&nbsp;Description</strong>&nbsp;field only.&nbsp;
    </p>
    <p>
      Please ensure&nbsp;<strong
        >Description for payee (optional) field</strong
      >&nbsp;is left&nbsp;<strong>blank</strong>
    </p>
    <p>Select <strong>Next&nbsp;</strong>to finalise the transaction.</p>
    <p>
      <img
        src="/assets/img/payid/nab/mceclip0.png"
        alt="mceclip0.png"
        width="352"
        height="352"
      />
    </p>
    <p>
      This should&nbsp;<strong>complete</strong>&nbsp;your Instant PayID
      Deposit.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p>
      <span class="wysiwyg-color-red120"
        ><strong>Please Note -&nbsp;&nbsp;</strong></span
      >PayID deposits, especially if this is your first deposit or you have
      deposited a large amount, may be held by your bank or take 24 hours to
      clear.&nbsp;
    </p>
    <h4>&nbsp;</h4>
    <h4 id="h_750d8d1b-38f1-4342-8908-0a331d10aba0">
      <span class="wysiwyg-color-blue120">NAB Internet Banking&nbsp;</span>
    </h4>
    <p>
      Log into your Internet Banking and select&nbsp;<strong
        >Funds transfer </strong
      >then choose<strong> Pay to a PayID</strong>
    </p>
    <p>
      <img
        src="/assets/img/payid/nab/blobid8.png"
        alt="blobid8.png"
        width="385"
        height="364"
      />
    </p>
    <p>
      Select the <strong>Email</strong> option and Enter the email address
      shown on<strong> your PayID deposit page&nbsp;</strong>
    </p>
    <p>select&nbsp;<strong>Verify&nbsp;</strong></p>
    <p>Enter the <strong>Amount</strong> to deposit</p>
    <p>
      Enter your <strong>Description/Reference</strong> in
      the&nbsp;<strong>Description</strong>&nbsp;field
    </p>
    <p>Select&nbsp;<strong>next</strong> to finalise the transaction.</p>
    <p>
      <img
        src="/assets/img/payid/nab/blobid0.png"
        alt="blobid0.png"
        width="382"
        height="268"
      /><br /><br />
    </p>
    <p>
      This should <strong>complete</strong> your Instant PayID Deposit.&nbsp;
    </p>
    <p>
      <span class="wysiwyg-color-red120"
        ><strong>Please Note -&nbsp;&nbsp;</strong></span
      >PayID deposits, especially if this is your first deposit or you have
      deposited a large amount, may be held by your bank or take 24 hours to
      clear.&nbsp;
    </p>
    <h4>&nbsp;</h4>
   <h4><span class="wysiwyg-color-blue120 wysiwyg-font-size-large">Need more help?&nbsp;</span></h4>
				<p>Please submit your request via WhatsApp -&nbsp;<a class="c-link" href="https://api.whatsapp.com/send?phone=61488880931" target="_blank" rel="noopener noreferrer">https://CashRemit.com.au/whatsApp</a>
				</p>
    <p>
      or start a conversation with us via Live Chat and our team will provide
      full instructions and advice.
    </p>
  </div>

  <div class="article-attachments">
    <ul class="attachments"></ul>
  </div>
</div>
</section>
`,
  cba: `<section class="article-info">
<div class="article-content">
  <div class="article-body">
    <h2>
      <span class="wysiwyg-color-blue120"
        >Instant Deposits with PayID!&nbsp;</span
      >
    </h2>
    <p>
      We have provided example screenshots and instructions on how to make
      PayID deposits from CBA. Please note -&nbsp; this is a guide only and
      your actual experience may vary.&nbsp;
    </p>
    <p>
      You will need your CashRemit PayID details from the PayID deposit page on
      your account. You will need to<strong> copy</strong> the CashRemit
      <strong>PayID Email Address </strong>and the
      <strong>Description/Reference</strong> displayed on your account.&nbsp;
    </p>
    <p>&nbsp;</p>
    <h4 id="h_f662aca1-f126-455a-a549-c33aaf6cd864">
      <span class="wysiwyg-color-blue120">CBA Mobile Banking App&nbsp;</span>
    </h4>
    <p>
      <span class="wysiwyg-color-black"
        >From your <strong>CBA&nbsp;Mobile Banking App&nbsp;</strong></span
      >
    </p>
    <p>
      <span class="wysiwyg-color-black"
        >Select<strong> Pay &amp; transfer</strong></span
      >
    </p>
    <p>
      <img
        src="/assets/img/payid/cba/blobid0.png"
        alt="blobid0.png"
        width="308"
        height="52"
      />
    </p>
    <p>
      <span class="wysiwyg-color-black"
        >Select <strong>Pay Someone&nbsp;</strong></span
      >
    </p>
    <p>
      <span class="wysiwyg-color-blue120"
        ><img
          src="/assets/img/payid/cba/blobid1.png"
          alt="blobid1.png"
          width="264"
          height="107"
      /></span>
    </p>
    <p>
      <span class="wysiwyg-color-black"
        >Select <strong>+ </strong>to pay someone NEW</span
      >
    </p>
    <p>
      <span class="wysiwyg-color-black"
        ><img
          src="/assets/img/payid/cba/blobid2.png"
          alt="blobid2.png"
          width="211"
          height="114"
      /></span>
    </p>
    <p>
      <span class="wysiwyg-color-black"
        >Select <strong>More </strong>and then Pay Someone new using<strong
          >&nbsp;Email Address&nbsp;</strong
        ></span
      >
    </p>
    <p>
      <span class="wysiwyg-color-black"
        ><img
          src="/assets/img/payid/cba/blobid3.png"
          alt="blobid3.png"
          width="203"
          height="138" />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<img
          src="/assets/img/payid/cba/blobid4.png"
          alt="blobid4.png"
          width="192"
          height="135"
      /></span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span class="wysiwyg-color-black"
        >Enter email address shown on<strong>
          your PayID deposit page.&nbsp;</strong
        ></span
      >
    </p>
    <p>
      <span class="wysiwyg-color-black">Select <strong>Next</strong></span>
    </p>
    <p>
      <span class="wysiwyg-color-black"
        >A popup window will show that CashRemit is registered under this
        PayID, select&nbsp;<strong>OK.</strong></span
      >
    </p>
    <p>
      <span class="wysiwyg-color-blue120"
        ><span>Enter the&nbsp;</span><strong>Amount</strong
        ><span>&nbsp;to deposit</span></span
      >
    </p>
    <p>
      Enter your <strong>Description/Reference&nbsp;</strong>in
      the&nbsp;<strong>Description</strong>&nbsp;field
    </p>
    <p>
      Please ensure&nbsp;<strong>Reference (optional) field</strong>&nbsp;is
      left&nbsp;<strong>blank</strong>
    </p>
    <p>Select <strong>Pay&nbsp;</strong>to finalise the transaction.</p>
    <p>&nbsp;</p>
    <p>
      <img
        src="/assets/img/payid/cba/blobid0.png"
        alt="blobid0.png"
        width="335"
        height="52"
      />
    </p>
    <p>&nbsp;</p>
    <p>
      This should <strong>complete</strong> your Instant PayID Deposit.&nbsp;
    </p>
    <p>
      <span class="wysiwyg-color-red120"
        ><strong>Please Note -&nbsp;&nbsp;</strong></span
      >PayID deposits, especially if this is your first deposit or you have
      deposited a large amount, may be held by your bank or take 24 hours to
      clear.&nbsp;
    </p>
    <p>&nbsp;</p>
    <h4 id="h_636503a0-6f0b-4712-bcc4-721dc33b9883">
      <span class="wysiwyg-color-blue120">CBA Internet Banking</span>
    </h4>
    <p>
      Once logged in, click on <strong>Pay someone&nbsp;</strong><br /><br />
    </p>
    <p>
      <img
        src="/assets/img/payid/cba/Screenshot_20181127-153424_CommBank.jpeg"
        alt="Screenshot_20181127-153424_CommBank.jpg"
        width="223"
        height="236"
      /><br /><br />Select the <strong>+</strong> button to add a New
      Payee.<br /><br /><img
        src="/assets/img/payid/cba/SmartSelect_20181128-180525_CommBank_1.jpeg"
        alt="SmartSelect_20181128-180525_CommBank__1_.jpg"
        width="359"
        height="211"
      />
    </p>
    <p>
      Select <strong>More</strong> and then select
      <strong>Email Address</strong><br /><br /><img
        src="/assets/img/payid/cba/SmartSelect_20181025-181739_CommBank.jpeg"
        alt="SmartSelect_20181025-181739_CommBank.jpg"
        width="191"
        height="159"
      /><br /><br /><img
        src="/assets/img/payid/cba/Screenshot_20181127-153435_CommBank.jpeg"
        alt="Screenshot_20181127-153435_CommBank.jpg"
        width="199"
        height="162"
      /><br /><br /><span class="wysiwyg-color-black"
        >Enter email address shown on<strong>
          your PayID deposit page.&nbsp;</strong
        ></span
      >
    </p>
    <p>Enter the <strong>Amount</strong> to deposit</p>
    <p>
      Enter your <strong>Description/Reference&nbsp;</strong>in
      the&nbsp;<strong>Description</strong>&nbsp;field
    </p>
    <p>
      <br /><img
        src="/assets/img/payid/cba/blobid1.png"
        alt="blobid1.png"
        width="361"
        height="219"
      />
    </p>
    <p>&nbsp;</p>
    <p>
      This should <strong>complete</strong> your Instant PayID Deposit.&nbsp;
    </p>
    <p>
      <span class="wysiwyg-color-red120"
        ><strong>Please Note -&nbsp;&nbsp;</strong></span
      >PayID deposits, especially if this is your first deposit or you have
      deposited a large amount, may be held by your bank or take 24 hours to
      clear.&nbsp;
    </p>
    <h4>&nbsp;</h4>
   <h4><span class="wysiwyg-color-blue120 wysiwyg-font-size-large">Need more help?&nbsp;</span></h4>
				<p>Please submit your request via WhatsApp -&nbsp;<a class="c-link" href="https://api.whatsapp.com/send?phone=61488880931" target="_blank" rel="noopener noreferrer">https://CashRemit.com.au/whatsApp</a>
				</p>
    <p>
      or start a conversation with us via Live Chat and our team will provide
      full instructions and advice.
    </p>
  </div>

  <div class="article-attachments">
    <ul class="attachments"></ul>
  </div>
</div>
</section>
`,
  westpac: `
     <section class="article-info">
	   	<div class="article-content">
			<div class="article-body">
				<h2><span class="wysiwyg-color-blue120">Instant Deposits with PayID!&nbsp;</span></h2>
				<p>We have provided example screenshots and instructions on how to make PayID deposits from Westpac. Please note -&nbsp; this is a guide only and your actual experience may vary.&nbsp;</p>
				<p>You will need your CashRemit PayID details from the PayID deposit page on your account. You will need to<strong> copy</strong> the CashRemit <strong>PayID Email Address </strong>and the <strong>Description/Reference</strong> displayed on your account.&nbsp;</p>
				<h4><span class="wysiwyg-color-blue120">Westpac Mobile Banking App&nbsp;</span></h4>
				<p>From your&nbsp;<strong>Westpac Mobile Banking App&nbsp;</strong>
				</p>
				<p>Select <strong>Make a Payment </strong>then select your account to deposit the payment from.&nbsp;</p>
				<p>Select <strong>Choose a payer or biller&nbsp;</strong>
				</p>
				<p><strong><img src="/assets/img/payid/wtp/blobid0.png" alt="blobid0.png" width="262" height="176"></strong>
				</p>
				<p>Select&nbsp;<strong>+&nbsp;</strong>to Add a new payee.&nbsp;&nbsp;</p>
				<p><strong><img src="/assets/img/payid/wtp/blobid1.png" alt="blobid1.png" width="278" height="104"></strong>
				</p>
				<p>Select <strong>New PayID/Mobile payee</strong>
				</p>
				<p><strong><img src="/assets/img/payid/wtp/blobid2.png" alt="blobid2.png" width="283" height="210"></strong>
				</p>
				<p>Select the <strong>Email</strong> option and add the email address shown on<strong> your PayID deposit page.&nbsp;</strong>
				</p>
				<p>Select<strong> Next</strong>
				</p>
				<p>Enter the&nbsp;<strong>Amount</strong>&nbsp;to deposit</p>
				<p>Enter your <strong>Description/Reference</strong> in the&nbsp;<strong>Description</strong>&nbsp;field</p>
				<p>Please ensure&nbsp;<strong>Reference (optional) field</strong>&nbsp;is left&nbsp;<strong>blank</strong>
				</p>
				<p><strong>Check</strong> your payment details and click<strong> Confirm</strong>
				</p>
				<p>This should&nbsp;<strong>complete</strong>&nbsp;your Instant PayID Deposit.&nbsp;</p>
				<p><span class="wysiwyg-color-red120"><strong>Please Note -&nbsp;&nbsp;</strong></span>PayID deposits, especially if this is your first deposit or you have deposited a large amount, may be held by your bank or take 24 hours to clear.&nbsp;</p>
				<p>&nbsp;</p>
				<h4 id="h_19c3905d-cd99-41c7-a293-a4c626e840ec"><span class="wysiwyg-color-blue120">Westpac Internet Banking&nbsp;</span></h4>
				<p><span class="wysiwyg-color-black">Once logged in, Select&nbsp;<strong>Make a Payment</strong></span>
				</p>
				<p>
					<img src="/assets/img/payid/wtp/mceclip2.png" alt="mceclip2.png" width="571" height="118">
				</p>
				<p>&nbsp;</p>
				<p>Select&nbsp;<strong>Pay new payee</strong>
				</p>
				<p>
					<img src="/assets/img/payid/wtp/mceclip3.png" alt="mceclip3.png" width="393" height="240">
				</p>
				<p>Select the <strong>Email</strong> option and add the email address shown on<strong> your PayID deposit page.&nbsp;</strong>
				</p>
				<p>Select <strong>Verify</strong>
				</p>
				<p><strong>Confirm</strong> Payee Details and set a <strong>Payee nickname</strong>
				</p>
				<p>Enter your <strong>Description/Reference</strong> in the&nbsp;<strong>Description</strong>&nbsp;field</p>
				<p>
					<img src="/assets/img/payid/wtp/blobid0.png" alt="blobid0.png" width="394" height="176">
				</p>
				<p>Enter the&nbsp;<strong>Amount</strong>&nbsp;to deposit and select <strong>Continue</strong>
				</p>
				<p>
					<img src="/assets/img/payid/wtp/blobid7.png" alt="blobid7.png" width="452" height="239">
				</p>
				<p>You will then be presented with a confirmation screen. Please&nbsp;<strong>Check</strong> your payment details and click<strong> Confirm</strong>
				</p>
				<p>This should&nbsp;<strong>complete</strong>&nbsp;your Instant PayID Deposit.&nbsp;</p>
				<p><span class="wysiwyg-color-red120"><strong>Please Note -&nbsp;&nbsp;</strong></span>PayID deposits, especially if this is your first deposit or you have deposited a large amount, may be held by your bank or take 24 hours to clear.&nbsp;</p>
				<h4>&nbsp;</h4>
				<h4><span class="wysiwyg-color-blue120 wysiwyg-font-size-large">Need more help?&nbsp;</span></h4>
				<p>Please submit your request via WhatsApp -&nbsp;<a class="c-link" href="https://api.whatsapp.com/send?phone=61488880931" target="_blank" rel="noopener noreferrer">https://CashRemit.com.au/whatsApp</a>
				</p>
				<p>or start a conversation with us via Live Chat and our team will provide full instructions and advice.</p>
			</div>
			<div class="article-attachments">
				<ul class="attachments"></ul>
			</div>
		</div>
  </section>
	<footer>
	</section>
`,
  bom: ``,
};

export const config = {
    SQUARE: {
        SQUARE_APPLICATION_ID: process.env.REACT_APP_SQUARE_APPLICATION_ID,
        SQUARE_LOCATION_ID: process.env.REACT_APP_SQUARE_LOCATION_ID,
        SDK: process.env.REACT_APP_SQUARE_SDK,
    },
    GBG: {
        BASE_URL: process.env.REACT_APP_GBG_URL,
        BACKEND_URL: process.env.REACT_APP_GBG_BACKEND_URL,
        USERNAME: process.env.REACT_APP_GBG_USERNAME,
        PASSWORD: process.env.REACT_APP_GBG_PASSWORD
    }
}
import styled from "styled-components";
import { Card } from "antd";

export const AuthCardBlock = styled(Card)`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  max-width: 300px;
  margin: 25px auto !important;

  @media (min-width: 768px) {
    max-width: 420px;
    width: 420px;
  }

  .ant-card-head {
    border: none;
  }

  .balance-card-wrapper{
    margin-top: 0;
  }

  .ant-card-head-title {
    font-size: 22px;
    font-weight: 700;
    position: relative;

    &:after {
      content: " ";
      position: absolute;
      height: 4px;
      width: 40px;
      bottom: 0;
      left: 0;
    }
  }
`;


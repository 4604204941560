// import React, { useState } from "react";
// import CashremitButton from "@cashremit/button";
// import { Box, Text, Image, Stack } from "@chakra-ui/react";
// import { DocumentUpdateCard } from "../../components/Dashboard/updateDocuments/DocumentCard.style";
// import AccountSettings from "components/AccountSettingModal/AccountSettingModal";
// import { useHistory } from "react-router-dom";
// const IDVerificationSuccess = () => {
//   const [open, setOpen] = useState(false)
//   const [open2, setOpen2] = useState(true)
//   const history = useHistory()

//   const handleChange = () => {
//     history.push("/dashboard");
//     // setOpen(true)
//     setOpen2(false)
//   };
//   return (
//     <>
//       {open2 &&
//         <DocumentUpdateCard style={{ textAlign: "center", marginTop: "40px" }}>
//           <Image src="/assets/right.png" sx={{ w: "5%", m: "auto", mb: "20px" }} />
//           <Box mb={5}>
//             <Box alignItems={"center"}>
//               <Box className="reminder-text mb-5">
//                 <Text fontSize="md" color="gray.500">
//                   Thank you for successfully completing the ID verification process.
//                   Your ID has been processed, and we kindly ask you to proceed to
//                   the next page to provide some additional details.
//                 </Text>
//               </Box>
//               <Box className="right-side">
//                 <Stack
//                   direction={["column", "column", "column", "column", "row"]}
//                   spacing={5}
//                 >
//                   <CashremitButton
//                     type="submit"
//                     onClick={handleChange}
//                   >
//                     Continue
//                   </CashremitButton>
//                 </Stack>
//               </Box>
//             </Box>
//           </Box>
//         </DocumentUpdateCard>
//       }
//       {open && !open2 && < AccountSettings />}
//     </>
//   );
// };

// export default IDVerificationSuccess;


import React, { useState } from "react";
import CashremitButton from "@cashremit/button";
import { Box, Text, Image, Stack } from "@chakra-ui/react";
import { DocumentUpdateCard } from "../../components/Dashboard/updateDocuments/DocumentCard.style";
import AccountSettings from "components/AccountSettingModal/AccountSettingModal";
import { useHistory } from "react-router-dom";
import Button from "@cashremit/button/Button";
const IDVerificationSuccess = () => {
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(true)
  const history = useHistory()

  const handleChange = () => {
    history.push("/dashboard");
    // setOpen(true)
    setOpen2(false)
  };

  return (
    <>
      {open2 &&
        <div style={{ textAlign: "center", marginTop: "40px", width: "40%", margin: "auto", paddingTop: "50px" }}>
          <Image
            src="/assets/protect.png"
            sx={{ w: "15%", m: "auto", mb: "20px" }}
          />
          <h3>ID Verification is Successfully</h3>
          <p style={{ fontSize: "18px", width: "80%", margin: "auto" }}>
            Thank you for upload your ID, We have now successfully verified your identity and you can start sending money with any delay, if need any have and question please email <href style={{ color: "blue" }}>helpcenter@remitzo.com</href>.
          </p>
          <Button
            type="primary"
            shape="round"
            style={{ width: "200px", marginTop: "30px" }}
            className="btn-green"
            onClick={handleChange}
          >
            Continue
          </Button>
        </div>
      }
      {open && !open2 && < AccountSettings />}
    </>
  );
};

export default IDVerificationSuccess;


import React, { useState, useEffect, useContext, useCallback } from "react";
import CompletePay from "@cashremit/complete-pay";
import { paymentAPI, settingsAPI, transactionAPI } from "../../http/api";
import { useLocation } from "react-router-dom";
import Loading from "./Loading";
import { Context } from "../../helpers/context/Store";
import notificationAPI from "../../http/api/notification";
import { Button } from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PrintCopy from "../Transactions/PrintCopy";
import { serialize } from "helpers/string.helper";
import { Modal, message } from "antd";
import UpdateDocumentsCard from "components/Dashboard/updateDocuments/UpdateDocumentsCard";
import { PaymentCard } from "./PaymentCard.style";

const CallbackPage = (props) => {
  let location = useLocation();
  const { history } = props;
  const [transaction, setTransaction] = useState(null);
  const [profile, setProfile] = useState([]);
  const [showdocument, setShowDocument] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [completePayOptions, setCompletePayOptions] = useState({
    loading: true,
    success: true,
    message: "Loading...",
    description: `Loading...`,
    thankyouText: "Thank you for using Remitzo",
  });

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const [, dispatch] = useContext(Context);
  const getNotifications = useCallback(() => {
    notificationAPI
      .getNotificationList()
      .then(({ data }) => {
        dispatch({ type: "SET_NOTIFICATIONS", payload: data.data });
      })
      .catch((err) => {
        console.warn(err);
        dispatch({ type: "SET_ERROR", payload: err });
      });
  }, [dispatch]);

  const isDocVerified = JSON.parse(
    localStorage.getItem("user")
  )?.is_doc_verify;

  const isSubmited = JSON.parse(
    localStorage.getItem("user")
  )?.is_doc_submitted;

  useEffect(() => {
    settingsAPI.getProfileData().then(({ data }) => {
      setProfile(data?.data);
    });
    return () => { };
  }, []);

  useEffect(() => {
    // const res = query.get("response");
    // const token = query.get("token");
    const res = new URLSearchParams(location.search).get("response");
    const token = new URLSearchParams(location.search).get("token");
    const orderId = new URLSearchParams(location.search).get("order_id");
    const paymentId = new URLSearchParams(location.search).get("payment_id");
    const kaasi =
      // content?.kaasi ||
      false;

    if (res && token) {
      paymentAPI
        .getTransaction(token, kaasi)
        .then(({ data }) => {
          setTransaction(data);
          setCompletePayOptions({
            success: !!(res === "success"),
            description: !!(res === "success")
              ? `Your Transfer has started. 2hrs delivery time. ${Boolean(data.users.is_verified === 0)
                ? `Please verify your account to avoid delay or cancel your transaction.`
                : ``
              }`
              : `Your Transaction is ${data.response.TransactionStatus}`,
            message: res.toUpperCase(),
            ...(data && data.users && Boolean(data.users.is_verified === 0)
              ? {
                btnText: "Verify Account",
              }
              : {}),
            loading: false,
            thankyouText: "Thank you for using Remitzo",
          });
          getNotifications();
        })
        .catch((err) => {
          setCompletePayOptions({
            success: false,
            description: err.response ? err.response.data.message : err.message,
            message: "ERROR",
            loading: false,
          });
        });
    } else if (orderId && paymentId) {
      transactionAPI
        .getTransactionConditionally(serialize({ refrence_no: orderId }))
        .then(({ data }) => {
          setTransaction(data);
          setCompletePayOptions({
            success: !!(data.data?.status === "Completed"),
            description: !!(data.data?.status === "Completed")
              ? `Your Transfer has started. 2hrs delivery time. ${Boolean(data.users?.is_verified === 0)
                ? `Please verify your account to avoid delay or cancel your transaction.`
                : ``
              }`
              : `Your Transaction is ${data.data.status}`,
            message: data.data?.status?.toUpperCase(),
            ...(data && data.users && Boolean(data.users.is_verified === 0)
              ? {
                btnText: "Verify Account",
              }
              : {}),
            loading: false,
            thankyouText: "Thank you for using Remitzo",
          });
          getNotifications();
        })
        .catch((err) => {
          setCompletePayOptions({
            success: false,
            description: err.response ? err.response.data.message : err.message,
            message: "ERROR",
            loading: false,
            thankyouText: "Thank you for using Remitzo",
          });
        });
    } else {
      history.push("/send-money");
      // setCompletePayOptions(op => ({
      //   ...op,
      //   loading: false
      // }))
    }
    return () => { };
  }, [location.search, history, getNotifications]);
  const footerButtonClickHandler = () => {
    console.log(transaction, "transaction.users");
    if (!isSubmited) {
      // history.push("/document-verification");
      // setShowDocument(true);
      if (profile.live_id_verification_session_id) {
        history.push(
          `/verify/documents/${profile.live_id_verification_session_id}`
        );
      } else {
        settingsAPI
          .updateUUID()
          .then((r) => {
            // localStorage.setItem("user", JSON.stringify(r.data.data));

            history.push(
              `/verify/documents/${r.data.data.live_id_verification_session_id}`
            );
          })
          .catch((e) => {
            console.log(e);
            message.error("UUID not found !!");
          });
      }
    } else {
      history.push("/send-money");
      // history.push("/dashboard");
    }
  };

  const PrintPDF = ({ tx }) => (
    <PDFDownloadLink
      document={<PrintCopy data={tx} />}
      fileName={`tx_copy_${tx.refrence_no}x.pdf`}
      className={`print-a-copy print-a-copy-${tx._id}`}
    >
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Download now!"
      }
    </PDFDownloadLink>
  );

  return completePayOptions.loading ? (
    <Loading />
  ) : (
    <>
      {showdocument ? (
        <UpdateDocumentsCard isModalVisible={isModalVisible} toggleModal={toggleModal} doc_status="true" />
      ) : (
        <Modal
          // title={`${completePayOptions.status === true ? "Payment Compeletion" : "Payment Failed"}`}
          title="Send Money - Status"
          visible={isModalVisible}
          onCancel={toggleModal}
          footer={null}
          className="custom-modal"
          style={{ top: 0, right: 0, left: 0, position: 'fixed', minWidth: '-webkit-fill-available' }}
          closable={false}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: `${window.innerHeight}px` }}>
            <PaymentCard title="Send Money - Status" bordered={false}>
              <div
                className="complete-pay-wrapper"
                style={{
                  display: "flex",
                  maxWidth: "900px",
                  margin: "0 auto",
                  overflow: "hidden",
                  justifyContent: "center",
                }}
              >
                <CompletePay
                  {...completePayOptions}
                  isDocVerified={isSubmited}
                  isFirstTransaction={profile?.is_first_transaction_completed}
                  onClick={() => footerButtonClickHandler()}
                >
                  <div>
                    <div>
                      <Button
                        type="primary"
                        shape="round"
                        style={{
                          margin: "20px 0px 30px 0px",
                        }}
                        className="btn-green"
                        onClick={() =>
                          document
                            .getElementsByClassName(`print-a-copy-${transaction._id}`)
                            .item(0)
                            .click()
                        }
                      >
                        Download Receipt
                      </Button>
                    </div>
                  </div>
                  {transaction && <PrintPDF tx={transaction} />}
                </CompletePay>
              </div>
            </PaymentCard>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CallbackPage;

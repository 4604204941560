import styled from "styled-components";
import { Card } from "antd";

export const StyledCompletePay = styled.div`
  text-align: center;
`;

export const CardWrap = styled(Card)`
  width: 500px;
  @media (max-width: 767px) {
    width: 100%;
    padding-top: 10px;
  }
  svg {
    height: 50px;
    width: 50px;
  }
`;

export const MessageHeadOne = styled.h1`
  ${({ success }) => (success ? `color: #1A4975;` : `color: #f00;`)}
  font-size: 28px;
  font-weight: 700;
  position: relative;
  margin-bottom: 25px;
  margin-top: 20px;
  &:after {
    content: "";
    background-color: #d6df22;
    border-radius: 20px;
    height: 4px;
    width: 200px;
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;
export const MsgDescription = styled.p`
  font-size: 14px;
  color: #666;
  line-height: 20px;  
`;
export const ThankYouWrap = styled.p`
  color: #1a4975;
  font-size: 17px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 30px;
`;

/* eslint-disable import/no-anonymous-default-export */
import { space } from "./sizing";
import colors from "./colors";

export const fontSize = {
  xxxs: "12px",
  xxs: "14px",
  xs: "16px",
  sm: "18px",
  md: "21px",
  lg: "24px",
  xl: "32px",
  xxl: "36px",
  xxxl: "48px",
  xxxxl: "60px",
};

export const fontWeight = {
  light: 200,
  regular: 300,
  semiBold: 400,
  bold: 500,
  extraBold: 600,
};

export const lineHeight = {
  sm: 0.75,
  md: 1.3,
  lg: 1.6,
  xl: 2,
};

export const letterSpacing = {
  default: 0,
};

export const typography = {
  h1: {
    color: colors.Green100,
    colorInverse: colors.Green50,
    fontSizeSm: fontSize.lg,
    fontSizeMd: fontSize.xl,
    fontSizeLg: fontSize.xxxl,
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.md,
    marginBottom: space.lg,
  },
  h2: {
    color: colors.Green100,
    colorInverse: colors.Green50,
    fontSizeSm: fontSize.lg,
    fontSizeMd: fontSize.xl,
    fontSizeLg: fontSize.xxl,
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.md,
    marginBottom: space.sm,
  },
  h3: {
    color: colors.Green100,
    colorInverse: colors.Green50,
    fontSizeSm: fontSize.sm,
    fontSizeLg: fontSize.md,
    fontWeight: fontWeight.semiBold,
    lineHeight: lineHeight.md,
    marginBottom: space.md,
  },
  h4: {
    color: colors.Green100,
    colorInverse: colors.Green50,
    fontSizeSm: fontSize.sm,
    fontSizeLg: fontSize.sm,
    fontWeight: fontWeight.semiBold,
    lineHeight: lineHeight.md,
    marginBottom: space.md,
  },
  h5: {
    color: colors.Green100,
    colorInverse: colors.Green50,
    fontSizeSm: fontSize.xxs,
    fontSizeLg: fontSize.xs,
    fontWeight: fontWeight.semiBold,
    lineHeight: lineHeight.lg,
    marginBottom: space.sm,
  },
  p: {
    color: colors.Green100,
    colorInverse: colors.Green50,
    fontSizeSm: fontSize.xxs,
    fontSizeMd: fontSize.xs,
    fontSizeLg: fontSize.sm,
    fontSizeXl: fontSize.md,
    fontWeight: fontWeight.regular,
    lineHeight: lineHeight.lg,
    marginBottom: space.lg,
  },
  li: {
    color: colors.Green100,
    colorInverse: colors.Green50,
    fontSizeSm: fontSize.xxs,
    fontSizeMd: fontSize.xs,
    fontSizeLg: fontSize.sm,
    fontSizeXl: fontSize.lg,
    fontWeight: fontWeight.regular,
    lineHeight: lineHeight.lg,
    marginBottom: space.xs,
    verticalPadding: space.xxs,
    bulletColor: colors.brandDefault,
    bulletSize: fontSize.xxxs,
    bulletTop: "10px",
    bulletLeft: space.lg,
  },
};

export default {
  fontSize,
  fontWeight,
  lineHeight,
  typography,
  letterSpacing,
};

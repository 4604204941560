/* eslint-disable import/no-anonymous-default-export */
import axios from "../axios";

export default {
	getNotificationList() {
		return axios.get("/users/notification/unread");
	},
	markAsReadNotification(id) {
		return axios.put("/users/notification/markAsRead", { id });
	}
};

import { Modal, Spin, message } from "antd";
import axios from "axios";
import IDVerificationFail from "components/VerificationStatus/verificationFail";
import IDVerificationSuccess from "components/VerificationStatus/verificationSuccess";
import { config } from "constants/config";
import settingsAPI from "http/api/settings";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SpinnerWrapper = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function resultTemplate() {
    return `<div style="display: flex; justify-content: center; align-items: center; height: 100%;">
    <div class="spinner" style="border: 5px solid #f3f3f3; border-top: 5px solid #3498db; border-radius: 50%; width: 50px; height: 50px; animation: spin 2s linear infinite;"></div>
  </div>`
}


const customTemplate = {
    result: {
        type: 'function',
        processor: 'mustache',
        provider: function () {
            return resultTemplate();
        },
    },
}

const UploadAndVerifyDocuments = (props) => {
    const [loader, setLoader] = React.useState(false);
    const [success, setSuccess] = useState(null);
    let currentUser = localStorage.getItem("user");
    currentUser = JSON.parse(currentUser);

    const getToken = () => {
        if (!currentUser.live_id_verification_session_id) {
            return setLoader(true)
        }
        const payload = new URLSearchParams();
        payload.append('username', config.GBG.USERNAME);
        payload.append('password', config.GBG.PASSWORD);
        payload.append('grant_type', 'password');
        payload.append('area', 'investigation');

        axios.post(`${config.GBG.BASE_URL}/idscanenterprisesvc/token`,
            payload,
        ).then(async res => {
            console.log("this is axios response for GBG --> ", res.data.access_token)
            localStorage.setItem('GBG_access_token', res.data.access_token);

            // runJourney(res.data.access_token)

            axios.get(`${config.GBG.BASE_URL}/idscanenterprisesvc/JourneyDefinition/GetAll`,
                {
                    'headers': {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': res.data.access_token
                    }
                }

            ).then(async response => {

                console.log("this is axios response for Journey --> ", response.data)
                localStorage.setItem('GBG_JourneyDefinitionId', response.data[1].JourneyDefinitionId);
                new window.GBG.Idscan.JourneyContainer({
                    backendUrl: config.GBG.BACKEND_URL,
                    container: "#sample",
                    tripleScanGuidancePage: true,
                    token: res.data.access_token,
                    journeyDefinitionId: response.data[1].JourneyDefinitionId,
                    fileUploadOnCameraCaptureJourneys: true,
                    previewPages: {
                        documentStep: {

                            smartCapture: false,
                            manualCapture: false
                        },
                        facematchStep: {
                            manualCapture: false
                        },
                        poaStep: {
                            manualCapture: false
                        },
                        passiveLivenessStep: {
                            manualCapture: false
                        },
                        covidStep: {
                            manualCapture: false,
                            fileUpload: true
                        }
                    },
                    manualCapture: {
                        enabled: true,
                        timeout: 15
                    },
                    assetsDirectory: '/wsdk-v15.0.2/assets',
                    onJourneyEvent: onJourneyEventCallBack,
                    templates: customTemplate,
                    // dictionary: translationDictionary,
                    additionalData: [
                        {
                            Name: "CustomerNumber",
                            Value: currentUser.live_id_verification_session_id
                        }
                    ],
                    smartCapture: {
                        workerScriptUrl: "/wsdk-v15.0.2/ides-micro.1b47351320a48dc3d9f9.js",
                        asmScriptUrl: "/wsdk-v15.0.2/idesmicro_asm.js",
                        timeout: 5000,
                        autoStart: false,
                        timeLogsEnabled: false
                    },
                    isVerboseLogEnabled: false,
                    hideLogo: true,
                    hideAutoCaptureHints: false
                }).initialize();


                setLoader(false);

            }).catch(error => {
                setLoader(false);
                console.log("this is axios error for Journey --> ", error);
            });
        }).catch(error => {
            setLoader(false);
            console.log("this is axios error for GBG --> ", error);
        });
    }

    const onJourneyEventCallBack = (event, meta, state) => {
        // eslint-disable-next-line default-case
        switch (event) {
            case "JOURNEY:END": {
                if (
                    (meta.name === "upload" && meta.status === "complete") ||
                    (meta.name === "send" && meta.status === "complete")
                ) {
                    console.log("TRANSFER:COMPLETE");
                }
                console.log("onJourneyEventPro event --->", event);
                console.log("onJourneyEventPro meta --->", meta);
                console.log("onJourneyEventPro state --->", state);
                setLoader(true);

                if (state.journey.isFinished) {
                    // if (state.journey.currentResult === "Refer" || state.journey.currentResult === "Failed") {
                    //     setSuccess("failed")
                    // }

                    // if (true) {
                    if (state.journey.currentResult) {
                        axios.get(`${config.GBG.BASE_URL}/idscanenterprisesvc/journey/get?journeyID=${state.journey.journeyId}`, {
                            headers: {
                                'Authorization': localStorage.getItem('GBG_access_token'),
                                'Content-Type': 'application/json',
                            }
                        }).then(r => {
                            console.log("this r data ==> ", JSON.stringify(r.data))
                            const d = r.data.ProcessedDocuments[0].ExtractedFields.reduce((obj, item) => {
                                if (['IssueDate', 'ExpiryDate', 'DocumentNumber', 'DocumentCategory', 'BirthDate', 'FirstName', 'LastName', "AddressCity", "AddressPostCode"].includes(item.Name)) {
                                    obj[item.Name] = item.Value;
                                }
                                return obj
                            }, {})

                            settingsAPI.gbgVerification({
                                'gbg_response': r.data,
                                // ...(d?.FirstName ? { 'first_name': d?.FirstName } : {}),
                                // ...(d?.LastName ? { 'last_name': d?.LastName } : {}),
                                // ...(d?.BirthDate ? { 'dob': d?.BirthDate } : {}),
                                'gbg_token': localStorage.getItem('GBG_access_token'),
                                'gbg_extracted_fields': {
                                    ...(d?.FirstName ? { 'first_name': d?.FirstName } : {}),
                                    ...(d?.LastName ? { 'last_name': d?.LastName } : {}),
                                    ...(d?.BirthDate ? { 'dob': d?.BirthDate } : {}),
                                    ...(d?.IssueDate ? { 'id_start': d?.IssueDate } : {}),
                                    ...(d?.IssueDate ? { 'id_start': d?.IssueDate, } : {}),
                                    ...(d?.ExpiryDate ? { 'id_expiry': d?.ExpiryDate, } : {}),
                                    ...(d?.DocumentCategory ? { 'id_type': d?.DocumentCategory?.replace(/\s+/g, '_'), } : {}),
                                    ...(d?.DocumentNumber ? { 'id_details': d?.DocumentNumber, } : {}),
                                    ...(d?.AddressCity ? { 'city': d?.AddressCity, } : {}),
                                    ...(d?.AddressPostCode ? { 'post_code': d?.AddressPostCode, } : {}),
                                }
                            }).then(re => {
                                localStorage.setItem("user", JSON.stringify(re.data.data));
                                message.success("Verify documents successfully!!!");
                                // setLoader(false);
                                setSuccess("success");
                                // history.push("/settings");
                            }).catch(error => {
                                console.log("this is axios error for update profile data --> ", error);
                                message.error("Verify documents failed !!!");
                                // setLoader(false);
                                setSuccess("failed");
                            })
                        })
                    }
                }

            }

            // eslint-disable-next-line no-fallthrough
            default:
                console.log("onJourneyEventPro default event --->", event);
                console.log("onJourneyEventPro default meta --->", meta);
                console.log("onJourneyEventPro default state --->", state);
                break;
        }
    }

    useEffect(() => {
        getToken();
        setLoader(true);

        return () => {
            setSuccess("");
        };
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            title="Complete Documents verification"
            open={props.isModalVisible}
            onCancel={props.toggleModal}
            footer={null}
            closable={false}
            className="custom-modal"
            style={{ top: 0, right: 0, left: 0, position: 'fixed', minWidth: '-webkit-fill-available' }}
        >
            {success === "success" ? (
                <div><IDVerificationSuccess /></div>
            ) :
                success === "failed" ? <IDVerificationFail /> :
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div className="doc-verification-wrap" style={{ width: '90%' }}>
                            {/* {loader ? (
                                <SpinnerWrapper>
                                    <Spin size="large" />
                                </SpinnerWrapper>
                            ) : <></>
                            } */}
                            {!currentUser.live_id_verification_session_id && <>UUID NOT FOUND !!!</>}
                            <div id="sample"></div>
                        </div>
                    </div>
            }
        </Modal>
    );
};

export default UploadAndVerifyDocuments;

import React, { useState } from "react";
import DocumentCard from "./DocumentCard";
import DocumentVerification from "./DocumentVerification";



const UpdateDocumentsCard = ({ isModalVisible, toggleModal, doc_status }) => {
  // console.log(isModalVisible, "isModalVisible")
  const [comp, setComp] = useState({ tabName: 'modal', tabData: null })
  const handleChange = (tab, data) => {
    setComp({ tabName: tab, tabData: data })
  }

  return (
    <>
      {(comp.tabName === "modal" && doc_status === "false") ? <DocumentCard handleChange={handleChange} isModalVisible={isModalVisible} toggleModal={toggleModal} /> : <DocumentVerification data={comp.tabData} isModalVisible={isModalVisible} toggleModal={toggleModal} />}
    </>
  );
};

export default UpdateDocumentsCard;

